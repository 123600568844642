import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Paper } from '@mui/material';
import FlagIcon from '@mui/icons-material/Flag';
import { red, grey } from '@mui/material/colors';
import QuestionsIndicator from './QuestionsIndicator'

const columns = [
  { id: 'followUp', label: 'Requires Followup', minWidth: 50, type: "flag" },
  { id: 'clientName', label: 'Client Name', minWidth: 170 },
  { id: 'submissionDate', label: 'Submission Date', minWidth: 170 },
  {
    id: 'questions',
    label: 'Questions',
    minWidth: 100,
    align: 'right',
    type: "questions"
  },
  {
    id: 'assignedTo',
    label: 'Assigned To',
    minWidth: 120,
    align: 'right',
  },
  {
    id: 'rfpStatus',
    label: 'RFP Status',
    minWidth: 120,
    align: 'right',
  },
  // {
  //   id: 'assignedTo',
  //   label: 'Assigned To',
  //   minWidth: 170,
  //   align: 'right',
  // },
  // {
  //   id: 'responseStatus',
  //   label: 'Response Status',
  //   minWidth: 170,
  //   align: 'right',
  // },
  // {
  //   id: 'nextStep',
  //   label: 'Next Step',
  //   minWidth: 170,
  //   align: 'right',
  // },
];

function createData(followUp, clientName, serviceRequested, priority, submissionDate, rfpStatus, assignedTo, responseStatus, nextStep, questions) {
  return {followUp,  clientName, serviceRequested, priority,assignedTo, submissionDate, rfpStatus, questions
  // assignedTo, responseStatus, nextStep 
    
  };
}

// Adjust the sample data accordingly
const rows = [
  createData(true, 'Smith, John', 'Estate Planning', 'High', '09/30/2024', 'Open', 'Jane Doe', 'Drafting', 'Finalize Draft', ['We alread have an existing will what should we do with this']),
  createData(false, 'Jones, Jackie', 'Will Creation', 'Medium', '10/12/2024', 'Pending', 'John Smith', 'Reviewing', 'Submit Response'),
  // Add more rows as needed
];

const FlagComponent = ({ value }) => {
  return (
    <FlagIcon style={{ color: value ? red[500] : grey[400] }} />
  );
};

const ActiveLeadsTable = () => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
      <TableContainer sx={{ maxHeight: 440 }}>
        <Table stickyHeader aria-label="sticky table" size="small">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
              return (
                <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                  {columns.map((column) => {
                    const value = row[column.id];
                    return (
                      <TableCell key={column.id} align={column.align}>
                        {column.format && typeof value === 'number' ? column.format(value) : 
                        column.type === 'flag' ? <FlagComponent value={value} /> :
                        column.type === 'questions' ? <QuestionsIndicator questions={value} /> :
                        value}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
}

export default ActiveLeadsTable;
