import React, { useState, useEffect, useRef } from 'react';
import { Box, Divider, TextField, Button, List, ListItem, ListItemText, Paper, Typography } from '@mui/material';

  let i=0
const Chat = ({selectedContact}) => {
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState('');
  const messageEndRef = useRef(null);

  const autoResponses = [
    "Sure, I can help with that.",
    "Could you provide more details?",
    "I'll need to check on that.",
    "Let's schedule a meeting to discuss.",
    // ... other responses
  ];
  const sendMessage = () => {
    // Add logic to send message to backend here
    setMessages([...messages, { sender: 'user', text: newMessage }]);
    setNewMessage('');
    
    // Simulate accountant's response
    setTimeout(() => {
      const accountantResponse = { sender: 'accountant', text: autoResponses[i++ %autoResponses.length] };
      setMessages(messages => [...messages, accountantResponse]);
    }, 1000); // Delay for realism
  };

  const scrollToBottom = () => {
    messageEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(scrollToBottom, [messages]);

  return (
    <div style={{ height: '100%', display: 'flex', flexDirection: 'column', width: 350}}>
     
      <Paper style={{ padding: '20px', margin: 'auto', flexGrow: 1, overflow: 'auto' }}>
      <Typography variant="h5" gutterBottom>
          Message
        </Typography>
        {!selectedContact &&
        <Typography variant="caption" color="secondary" gutterBottom>
          Select a contact of dial someone new
        </Typography>
        }
        {selectedContact &&
        <Typography variant="h6" gutterBottom>
          {selectedContact.name}
        </Typography>
        }
        
        <Paper style={{ minHeight: 200, maxHeight: 500, overflow: 'auto', marginBottom: 8 }}>
        <Box sx={{ p: 2 }}>
          <Typography gutterBottom variant="h5" component="div">
            Chat
          </Typography>
        <Typography color="text.secondary" variant="body2">
          You can sync these messages to your phone SMS or other messageing service by updating Settings
        </Typography>
      </Box>
      <Divider light />
        <List>
          {messages.map((message, index) => (
            <ListItem key={index} alignItems="flex-start">
              <ListItemText
                primary={message.sender === 'user' ? 'You' : 'Accountant'}
                secondary={<Typography variant="body2" color="textPrimary">{message.text}</Typography>}
              />
            </ListItem>
          ))}
          <div ref={messageEndRef} />
        </List>
      </Paper>
      <TextField
        fullWidth
        label="Type a message"
        value={newMessage}
        onChange={(e) => setNewMessage(e.target.value)}
        onKeyDown={(e) => e.key === 'Enter' && sendMessage()}
        margin="normal"
        variant="outlined"
      />
      <Button variant="contained" color="primary" onClick={sendMessage}>
        Send
      </Button>
      </Paper>
    </div>
  );
};

export default Chat;
