import React, { useState, useEffect } from 'react';
import { Container, List, ListItem, ListItemText, IconButton, Paper, Typography } from '@mui/material';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import useAxiosWithCredentials from '../hooks/useAxiosWithCredentials';
// A mock function to fetch voicemails from your backend
// Replace this with your actual API call
// const fetchVoicemails = async () => {
  // // Example response structure
  // return [
  //   { id: 1, caller: '+1234567890', date: '2024-03-06', transcription: 'Hello, this is a test voicemail.', audioUrl: 'https://example.com/audio/1.mp3' },
  //   // Add more voicemails here
  // ];
  
      
// };

const VoicemailList = () => {
  const [voicemails, setVoicemails] = useState([]);
  const [audio, setAudio] = useState(null);
  const [playing, setPlaying] = useState('');

  const axiosWithCredentials = useAxiosWithCredentials();
  const loadVoicemails = async () => {
      const response = await axiosWithCredentials.get(`/crm/phone/voicemail/+18667216144`);
      console.log(JSON.stringify(response))
      const data = await response.data;
      setVoicemails(data);
    };
  useEffect(() => {
    loadVoicemails();
  }, []);

  const togglePlay = (audioUrl) => {
    if (playing === audioUrl) {
      audio.pause();
      setPlaying('');
    } else {
      if (audio) {
        audio.pause();
      }
      const newAudio = new Audio(audioUrl);
      setAudio(newAudio);
      newAudio.play();
      setPlaying(audioUrl);
      newAudio.onended = () => setPlaying('');
    }
  };

  return (
      <List style={{ width: '100%' }}>
        {voicemails.map((voicemail) => (
          <ListItem key={voicemail.id} divider>
            <ListItemText primary={`${voicemail.from} - ${voicemail.date}`} secondary={voicemail.transcription} />
            <IconButton onClick={() => togglePlay(voicemail.audioUrl)}>
              {playing === voicemail.audioUrl ? <PauseIcon /> : <PlayArrowIcon />}
            </IconButton>
          </ListItem>
        ))}
      </List>
  );
};

export default VoicemailList;
