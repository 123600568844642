import React, { createContext, useState, useContext } from 'react';
import { Snackbar } from '@mui/material';
import Alert from '@mui/material/Alert';

const MessageContext = createContext();

export const MessageProvider = ({ children, customRenderer = null }) => {
  const [message, setMessage] = useState(null);
  const [severity, setSeverity] = useState('info');

  const translateMessage = (msg, type) => {
    // Translation logic for 
    console.log(msg)
    switch (msg) {
      case 'PreSignUp failed with error [NO LICENSE].':
        return 'Looks like your company hasn\'t purchased a license or it has expired.';
      case 'InvalidPasswordException':
        return 'The password you entered is invalid. Please follow the password guidelines.';
      default:
        console.log("MSG THAT HAS NO TRANSLATION:", msg)
        return msg;
    }
  };

  const showMessage = (msg, type = 'info') => {
    const translatedMessage = translateMessage(msg, type);
    console.log('translatedMessage', translatedMessage); // Check the output in console
    setMessage(translatedMessage); // Set the translated message
    setSeverity(type); // Set severity
  };

  const hideMessage = () => {
    setMessage(null);
  };

  const defaultRenderer = () => (
    <Snackbar
      open={Boolean(message)}
      autoHideDuration={6000}
      onClose={hideMessage}
    >
      <Alert onClose={hideMessage} severity={severity}>
        {message}
      </Alert>
    </Snackbar>
  );


  return (
    <MessageContext.Provider value={{ showMessage, hideMessage, translateMessage }}>
      {children}
      {customRenderer ? customRenderer({ message, severity, hideMessage }) : defaultRenderer()}
    </MessageContext.Provider>
  );
};

export const useMessage = () => useContext(MessageContext);
