// Layout.js
import React, {useState} from 'react';
import { Box, CssBaseline, AppBar, Toolbar, IconButton, Typography, Drawer, List, ListItem, ListItemIcon, Avatar } from '@mui/material';
import { Menu as MenuIcon, Home as HomeIcon, Settings as SettingsIcon, AccountCircle as AccountCircleIcon } from '@mui/icons-material';
import { Flex } from '@chakra-ui/react'
import { Outlet, useNavigate, useLocation } from 'react-router-dom';
import { Grid, Paper } from '@mui/material';
import ViewMenu from "./ViewMenu"

import ContactListControl from './ContactListControl'
import ActivityView from './ActivityView'
import ContactToolbox from './ContactToolbox'
import ActivityForm from './ActivityForm'
import ActivityLog from './ActivityLog'
import TopBarProfileIcon from './TopBarProfileIcon'
import Header from './Header'
import LeftBar from './LeftBar'
import {useAuthenticator} from "@aws-amplify/ui-react"
export default function CRMLayout() {
  const location = useLocation();
  const isProfileNav = location.pathname.includes('/profile');
  
  const navigate = useNavigate(); // Hook for navigation
  const [selectedContact, setSelectedContact] = useState(null);
  
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [selectedActivity, setSelectedActivity] = useState(null);
  
  const [phoneNumber, setPhoneNumber] = useState('');
  const selectContact = (contact) => {
    // setContacts(contacts.filter(contact => contact.id !== contactId));
    setPhoneNumber(contact.phone)
    setSelectedContact(contact)
  };
  const [currentView, setCurrentView] = useState('connect');
  const switchViews = (view) =>{
      setCurrentView(view)
      navigate("/"+view)
      setDrawerOpen(false)
  }
  const handleDialButton = (key) => {
    setPhoneNumber(phoneNumber + key.value);
  };

  
  
  const [editMode, setEditMode] = useState(false);
  const selectActivity = (activity) => {
    
    if(activity){
      setEditMode(true)
      setSelectedActivity(activity)
    }
    else{
      setEditMode(false)
      setSelectedActivity({
        date: "5/27/1977", 
        notes: '',
        type: 'Note'
      })
    }
    
    
  };
  
  
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };
const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  
  const {route, user,  signOut} = useAuthenticator(context => [
    context.route,
    context.user,
    context.signOut
    ])
  
  return (
    <Flex direction="column" height="100vh">
      <Header isProfileNav={isProfileNav} signOut={signOut} user={{}} />

      {/*<CssBaseline />
      <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            sx={{ mr: 2 }}
            onClick={() => setDrawerOpen(!drawerOpen)}
          >
            <MenuIcon />
          </IconButton>
          {viewMap[currentView]?.icon}
          
          
          <Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1 }}>
            {viewMap[currentView]?.title}
          </Typography>
          <TopBarProfileIcon signOut={signOut} anchorElUser={anchorElUser} handleOpenUserMenu={handleOpenUserMenu} handleCloseUserMenu={handleCloseUserMenu} />
        </Toolbar>
      </AppBar>
      
      <ViewMenu switchViews={switchViews} currentView={currentView} drawerOpen={drawerOpen} setDrawerOpen={setDrawerOpen}/>
        */ }
      <Flex flex="1" >
        {!isProfileNav && <LeftBar className="desktop-nav" /> }
        <div className="main-content">
          <Outlet />
        </div>
      </Flex>
      
      
    </Flex>
  );
}