import React, { useState } from 'react';

import { TextField, Button, Box, List, ListItem, ListItemText, Typography, Paper } from '@mui/material';

const ZoomMeeting = () => {
  const [isCameraOn, setIsCameraOn] = useState(true);
  const [isMicOn, setIsMicOn] = useState(true);

  const toggleCamera = () => {
    setIsCameraOn(!isCameraOn);
  };

  const toggleMic = () => {
    setIsMicOn(!isMicOn);
  };

  return (
    <Box>
      {/* Video Area */}
      <div style={{ flexGrow: 1, backgroundColor: '#000', color: '#fff', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        {isCameraOn ? <p>Video Feed</p> : <p>Camera is off</p>}
      </div>

      {/* Controls */}
      <div style={{ display: 'flex', justifyContent: 'space-around', padding: '10px', backgroundColor: '#f3f3f3' }}>
        <button onClick={toggleCamera}>{isCameraOn ? 'Turn Camera Off' : 'Turn Camera On'}</button>
        <button onClick={toggleMic}>{isMicOn ? 'Mute' : 'Unmute'}</button>
        {/* Add more controls as needed */}
      </div>
    </Box>
  );
};

export default ZoomMeeting;
