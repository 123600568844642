import React, { useState } from 'react';
import { Box, Tab, Tabs } from '@mui/material';

const ActivityTabs = ({ onTabChange, tabValue }) => {
  const handleTabChange = (event, newValue) => {
    onTabChange(newValue);
  };

  return (
    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
      <Tabs value={tabValue} onChange={handleTabChange} aria-label="activity tabs">
        <Tab label="All" value="all" />
        <Tab label="Notes" value="notes" />
        <Tab label="Message" value="messages" />
        <Tab label="Voice" value="voice" />
        <Tab label="Video" value="video" />
        <Tab label="Files" value="files" />
      </Tabs>
    </Box>
  );
};

export default ActivityTabs;
