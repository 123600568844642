import React, { useState } from 'react';
import { Avatar, Button, TextField, Typography, Grid, Box } from '@mui/material';
import { styled } from '@emotion/styled';
import { css } from '@emotion/react';

const useStyles = {
  root: css`
    flex-grow: 1;
    padding: 16px;
  `,
  avatar: css`
    width: 96px;
    height: 96px;
    margin-bottom: 16px;
  `,
  input: css`
    display: none;
  `,
  button: css`
    margin-top: 16px;
  `,
};

const Profile = () => {
  const [profile, setProfile] = useState({
    name: '',
    email: '',
    phone: '',
    address: '',
    profilePicture: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setProfile({ ...profile, [name]: value });
  };

  const handleProfilePictureChange = (e) => {
    if (e.target.files.length > 0) {
      const file = e.target.files[0];
      const reader = new FileReader();
      reader.onload = (upload) => {
        setProfile({ ...profile, profilePicture: upload.target.result });
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <Box css={useStyles.root}>
      <Typography variant="h4" gutterBottom>
        Profile
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={4} md={3}>
          <input
            accept="image/*"
            css={useStyles.input}
            id="profile-picture-upload"
            type="file"
            onChange={handleProfilePictureChange}
          />
          <label htmlFor="profile-picture-upload">
            <Avatar
              alt="Profile Picture"
              src={profile.profilePicture}
              css={useStyles.avatar}
            />
          </label>
          <Button variant="contained" color="primary" component="span" css={useStyles.button}>
            Upload
          </Button>
        </Grid>
        <Grid item xs={12} sm={8} md={9}>
          <TextField
            fullWidth
            label="Name"
            name="name"
            value={profile.name}
            onChange={handleChange}
            margin="normal"
            variant="outlined"
          />
          <TextField
            fullWidth
            label="Email"
            name="email"
            value={profile.email}
            onChange={handleChange}
            margin="normal"
            variant="outlined"
          />
          <TextField
            fullWidth
            label="Phone Number"
            name="phone"
            value={profile.phone}
            onChange={handleChange}
            margin="normal"
            variant="outlined"
          />
          <TextField
            fullWidth
            label="Address"
            name="address"
            value={profile.address}
            onChange={handleChange}
            margin="normal"
            variant="outlined"
          />
          <Button variant="contained" color="primary" css={useStyles.button}>
            Save
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Profile;

