import { useState, useEffect } from 'react';
import { Box, Typography, IconButton, Collapse, TextField, Button } from '@mui/material';
import { Email as EmailIcon, Phone as PhoneIcon, ExpandMore as ExpandMoreIcon, Edit as EditIcon, Save as SaveIcon, Cancel as CancelIcon } from '@mui/icons-material';
import useAxiosWithCredentials from '../hooks/useAxiosWithCredentials';

const ContactInfo = ({ contactId }) => {
  const axiosWithCredentials = useAxiosWithCredentials();
  const [showMore, setShowMore] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [contactDetails, setContactDetails] = useState({});
  const [editedDetails, setEditedDetails] = useState({});
  
  useEffect(() => {
    loadContactDetails();
  }, [contactId]);

  const handleShowMore = () => {
    setShowMore(!showMore);
  };

  const toggleEditMode = () => {
    setEditMode(!editMode);
    setEditedDetails(contactDetails); // Initialize form with current details
  };

  const handleEditChange = (field) => (event) => {
    setEditedDetails({ ...editedDetails, [field]: event.target.value });
  };

  const saveEdits = async () => {
    try {
      // Implement API call to save edited details
      await axiosWithCredentials.put(`/crm/contact/details/${contactId}`, editedDetails);
      setContactDetails(editedDetails);
      setEditMode(false);
      // Optionally, refetch contact details to ensure UI consistency
    } catch (error) {
      console.error("Error saving edits:", error);
      // Handle save errors appropriately
    }
  };

  const loadContactDetails = async () => {
    try {
      const response = await axiosWithCredentials.get(`/crm/contact/meta/${contactId}`);
      setContactDetails(response.data);
    } catch (error) {
      console.error("Error loading contact details:", error);
    }
  };

  return (
    <Box sx={{ my: 2, p: 2, border: '1px solid #e0e0e0', borderRadius: '4px' }}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="h6" gutterBottom>
          Contact Info
        </Typography>
        <IconButton onClick={toggleEditMode} size="small">
          {editMode ? <SaveIcon /> : <EditIcon />}
        </IconButton>
      </Box>
      {editMode ? (
        <>
          <TextField
            label="Email"
            variant="outlined"
            size="small"
            fullWidth
            margin="dense"
            value={editedDetails.email || ''}
            onChange={handleEditChange('email')}
          />
          <TextField
            label="Phone"
            variant="outlined"
            size="small"
            fullWidth
            margin="dense"
            value={editedDetails.phone || ''}
            onChange={handleEditChange('phone')}
          />
          <TextField
            label="Title"
            variant="outlined"
            size="small"
            fullWidth
            margin="dense"
            value={editedDetails.title || ''}
            onChange={handleEditChange('title')}
          />
          <TextField
            label="Address"
            variant="outlined"
            size="small"
            fullWidth
            margin="dense"
            value={editedDetails.address || ''}
            onChange={handleEditChange('address')}
          />
          <Button variant="contained" color="primary" startIcon={<SaveIcon />} onClick={saveEdits} sx={{ mt: 2 }}>
            Save
          </Button>
          <Button variant="text" startIcon={<CancelIcon />} onClick={() => setEditMode(false)} sx={{ mt: 2, ml: 1 }}>
            Cancel
          </Button>
        </>
      ) : (
        <>
          <Box display="flex" alignItems="center" mb={1}>
            <EmailIcon color="primary" sx={{ mr: 1 }} />
            <Typography variant="body1">{contactDetails.email}</Typography>
          </Box>
          <Box display="flex" alignItems="center" mb={1}>
            <PhoneIcon color="primary" sx={{ mr: 1 }} />
            <Typography variant="body1">{contactDetails.phone}</Typography>
          </Box>
          <Box display="flex" alignItems="center" mb={2}>
            <Typography variant="body1">{contactDetails.address}</Typography>
          </Box>
        </>
      )}
      {contactDetails.additional && (
        <>
          <IconButton onClick={handleShowMore} aria-expanded={showMore} aria-label="show more">
            <ExpandMoreIcon />
          </IconButton>
          <Collapse in={showMore} timeout="auto" unmountOnExit>
            {Object.entries(contactDetails.additional).map(([key, value]) => (
              <Typography key={key} variant="body2">{`${key}: ${value}`}</Typography>
            ))}
          </Collapse>
        </>
      )}
    </Box>
  );
};

export default ContactInfo;
