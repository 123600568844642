import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Paper } from '@mui/material';

const columns = [
  { id: 'leadName', label: 'Lead Name', minWidth: 170 },
  { id: 'serviceRequested', label: 'Service Requested', minWidth: 170 },
  {
    id: 'priority',
    label: 'Priority',
    minWidth: 100,
    align: 'right',
  },
  {
    id: 'domicile',
    label: 'Domicile',
    minWidth: 100,
    align: 'right',
  },
  {
    id: 'responseDueDate',
    label: 'Response Due',
    minWidth: 120,
    align: 'right',
  },
  {
    id: 'rfpStatus',
    label: 'RFP Status',
    minWidth: 120,
    align: 'right',
  },
  // {
  //   id: 'assignedTo',
  //   label: 'Assigned To',
  //   minWidth: 170,
  //   align: 'right',
  // },
  // {
  //   id: 'responseStatus',
  //   label: 'Response Status',
  //   minWidth: 170,
  //   align: 'right',
  // },
  // {
  //   id: 'nextStep',
  //   label: 'Next Step',
  //   minWidth: 170,
  //   align: 'right',
  // },
];

function createData(leadName, serviceRequested, priority, domicile,  responseDueDate, rfpStatus, assignedTo, responseStatus, nextStep) {
  return { leadName, serviceRequested, domicile, priority, responseDueDate, rfpStatus, 
  // assignedTo, responseStatus, nextStep 
    
  };
}

// Adjust the sample data accordingly
const rows = [
  createData('Smith, John', 'Estate Planning', 'High', 'MA', '09/30/2024', 'Open', 'Jane Doe', 'Drafting', 'Finalize Draft'),
  createData('Jones, Jackie', 'Will Creation', 'Medium', 'CT', '10/12/2024', 'Pending', 'John Smith', 'Reviewing', 'Submit Response'),
  // Add more rows as needed
];

const ActiveLeadsTable = () => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
      <TableContainer sx={{ maxHeight: 440 }}>
        <Table stickyHeader aria-label="sticky table" size="small">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
              return (
                <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                  {columns.map((column) => {
                    const value = row[column.id];
                    return (
                      <TableCell key={column.id} align={column.align}>
                        {column.format && typeof value === 'number' ? column.format(value) : value}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
}

export default ActiveLeadsTable;
