import { Box } from "@chakra-ui/react";
import { useNavigate } from 'react-router-dom';
import React, { useState } from 'react';
import TopBarProfileIcon from './TopBarProfileIcon'
import Icon from './common/Icon.js'
// import LeftBarMain from '../Layout/LeftBarMain';
// import LeftBarProfile from '../Layout/LeftBarProfile';
import { useAuthenticator } from '@aws-amplify/ui-react'

const Header = ({isProfileNav}) => {
  const [showMenu, setShowMenu] = useState(false)
  
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const handleOpenUserMenu = (event) => setAnchorElUser(event.currentTarget);
  const handleCloseUserMenu = () => setAnchorElUser(null);
  
  const {route, user, signOut} = useAuthenticator(context => [
    context.route,
    context.user,
    context.signOut
  ])

  return (
    <Box
      as="header"
      className="header"
    >
      <Box className="hamburger-menu" onClick={() => setShowMenu(!showMenu)}>
        <Icon name="menu" />
      </Box>
      {/*showMenu && !isProfileNav && <LeftBarMain />*/}
      {/*showMenu && isProfileNav && <LeftBarProfile />*/}
      <a className="arcault-logo" href="/" >
        <img src="/logo.png" alt="Logo" />
      </a>
      <TopBarProfileIcon signOut={signOut} anchorElUser={anchorElUser} handleOpenUserMenu={handleOpenUserMenu} handleCloseUserMenu={handleCloseUserMenu} />
    </Box>
  );
}

export default Header;