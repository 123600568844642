import React, {useState, useEffect} from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { fetchUserAttributes } from 'aws-amplify/auth';
import useAxiosWithCredentials from '../hooks/useAxiosWithCredentials'

import { Avatar,Box, Menu, MenuButton, MenuList, MenuItem, IconButton } from '@chakra-ui/react';
import { ChakraProvider } from '@chakra-ui/react'
import theme from '../theme.js'

const TopBarProfileIcon = ({signOut, handleCloseUserMenu }) => {
  
  const axiosWithCredentials = useAxiosWithCredentials()
  const [user, setUser] = useState({name: ''}); // Initialize a key state
  const navigate = useNavigate();

  const loadUserInfo = async () => {
    try {
      //try to get name from cognito first
      const userAttributes = await fetchUserAttributes();
      if(userAttributes.given_name){
        setUser({...user, name: `${userAttributes.given_name} ${userAttributes.family_name || ''}`});
      }
    } catch (error) {
      console.log(error);
    }
  };
  
  useEffect(() => {
    loadUserInfo();
  }, []);

  const options = [
    {label: 'Profile', to: 'profile'},
    {label: 'Logout', onClick: signOut }
  ];
    
  const handleNavigation = () => {
    navigate('/profile'); // Navigates to the About page
  }

  return (
    <ChakraProvider theme={theme} >
      <Box sx={{ flexGrow: 0 }}>
       <Menu>
        <MenuButton
          as={IconButton}
          aria-label="Options"
          className="profile-icon"
          icon={
            <Avatar
              name={user.name}
              bg="accent.400"
              src={user.avatarUrl} // Use the user's avatar URL or fallback to initials
            />
          }
        />
        <MenuList>
          {options.map((option) => (
            <MenuItem key={option.label} onClick={handleCloseUserMenu}>
              {option.to ? (
                <MenuItem component={Link} onClick={handleNavigation}>{option.label}</MenuItem>
              ) : (
                <MenuItem  onClick={option.onClick}>{option.label}</MenuItem>
              )}
            </MenuItem>
          ))}
        </MenuList>
      </Menu>
    </Box>
  </ChakraProvider>
  );
};
export default TopBarProfileIcon;