import React, { useState, useEffect } from 'react';
import { Box, Chip, Typography, Button, Dialog, DialogTitle, DialogContent, DialogActions, TextField } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import useAxiosWithCredentials from '../hooks/useAxiosWithCredentials'; // Assumed import

const TagsEditDialog = ({ tags, open, initialTags, onSave, onClose }) => {
  const [editTags, setEditTags] = useState([]);
  

  useEffect(() => {
    setEditTags([...initialTags]);
  }, [initialTags, open]);

  const handleTagDelete = (tagToDelete) => {
    setEditTags((tags) => tags.filter((tag) => tag !== tagToDelete));
  };

  const handleAddTag = (event) => {
    console.log(event.key +" "+event.target.value.trim())
    if (event.key === 'Enter' && event.target.value.trim()) {
      setEditTags((tags) => [...tags, event.target.value.trim()]);
      event.target.value = ''; // Clear input after adding
    }
    console.log(editTags)
  };

  const handleSave = () => {
    onSave(editTags);
  };

  return (
    <Dialog open={open} onClose={onClose} aria-labelledby="edit-tags-dialog-title">
      <DialogTitle id="edit-tags-dialog-title">Edit Tags</DialogTitle>
      <DialogContent>
        <Box display="flex" flexWrap="wrap" gap={1} mb={2}>
          {editTags.map((tag, index) => (
            <Chip
              key={index}
              label={tag}
              onDelete={() => handleTagDelete(tag)}
              variant="outlined"
              color="primary"
            />
          ))}
        </Box>
        <TextField
          onKeyDown={handleAddTag}
          label="Add a tag"
          variant="outlined"
          fullWidth
          placeholder="Press enter to add"
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleSave} variant="contained">Save</Button>
      </DialogActions>
    </Dialog>
  );
};

const TagsSection = ({ contactId }) => {
  const axiosWithCredentials = useAxiosWithCredentials();
  const [tags, setTags] = useState([]);
  const [editDialogOpen, setEditDialogOpen] = useState(false);

  useEffect(() => {
    const loadTags = async () => {
      try {
        const response = await axiosWithCredentials.get(`/crm/tags/user-tags/${contactId}`);
        setTags(response.data.tags || []);
      } catch (error) {
        console.error("Error loading tags:", error);
      }
    };

    if (contactId) {
      loadTags();
    }
  }, [contactId, axiosWithCredentials]);

  const handleEditTagsOpen = () => setEditDialogOpen(true);
  const handleEditTagsClose = () => setEditDialogOpen(false);

  const handleSaveEditedTags = async (newTags) => {
    console.log(newTags)
    try {
      await axiosWithCredentials.put(`/crm/tags/user-tags/${contactId}`, { tags: newTags });
      setTags(newTags);
      handleEditTagsClose();
    } catch (error) {
      console.error("Error updating tags:", error);
    }
  };

  return (
    <Box sx={{ my: 2 }}>
      <Typography variant="h6" gutterBottom>Tags</Typography>
      <Box display="flex" flexWrap="wrap" gap={1}>
        {tags.map((tag, index) => (
          <Chip key={index} label={tag} variant="outlined" />
        ))}
      </Box>
      <Button
        startIcon={<EditIcon />}
        onClick={handleEditTagsOpen}
        sx={{ mt: 1 }}
        size="small"
      >
        Edit Tags
      </Button>
      <TagsEditDialog
        tags={tags}
        open={editDialogOpen}
        initialTags={tags}
        onClose={handleEditTagsClose}
        onSave={handleSaveEditedTags}
      />
    </Box>
  );
};

export default TagsSection;
