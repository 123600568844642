import React, { useState, useEffect } from 'react';
import {
  Pagination,
  Box,
  Card,
  CardActionArea,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Typography,
  Button,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import useAxiosWithCredentials from '../hooks/useAxiosWithCredentials';
// Dummy RFP data
const rfpList = [
  {
    id: 1,
    title: 'Smith Estate Plan',
    summary: 'Looking for experienced estate planning services for a complex estate.',
    details: 'Full RFP details for Estate Planning...',
    postedOn: '01/15/2024',
    numberOfResponses: 5,
    estimatedValue: 25000,
    location: 'New York, NY',
  },
  {
    id: 2,
    title: 'Johnson Estate Plan',
    summary: 'Expert needed for a high-net-worth estate plan with international implications.',
    details: 'The Johnsons need a detailed estate plan that addresses overseas properties, tax implications, and the establishment of educational trusts for beneficiaries...',
    estimatedValue: 25000,
  },
  {
    id: 3,
    title: 'Williams Estate Plan',
    summary: 'Estate planning services needed for a charitable-focused family estate.',
    details: 'The Williams family is looking for an estate plan that maximizes charitable contributions while providing for family members and minimizing taxes...',
    estimatedValue: 25000,
  },
  {
    id: 4,
    title: 'Brown Estate Plan',
    summary: 'Guidance required for the Brown family estate with business assets.',
    details: 'Estate planning is needed to handle succession planning for the family business, along with creating a living trust and powers of attorney...',
    estimatedValue: 25000,
  },
  {
    id: 5,
    title: 'Jones Estate Plan',
    summary: 'Family estate planning with an emphasis on special needs trust creation.',
    details: 'The Jones estate plan must consider a special needs trust for a dependent, alongside other estate planning instruments like wills and healthcare directives...',
    estimatedValue: 25000,
  },
  {
    id: 6,
    title: 'Garcia Estate Plan',
    summary: 'Estate plan development needed for Garcia family, including real estate in multiple countries.',
    details: 'The Garcia family requires an estate plan that deals with diverse international assets, tax efficiency, and legal considerations across jurisdictions...',
    estimatedValue: 25000,
  },
  {
    id: 7,
    title: 'Miller Estate Plan',
    summary: 'Complex estate planning required for a blended family structure.',
    details: 'The Millers need an estate plan that accounts for a blended family, ensuring fair distribution of assets, establishment of trusts, and guardianship arrangements...',
    estimatedValue: 25000,
  },
  {
    id: 8,
    title: 'Davis Estate Plan',
    summary: 'Davis family seeks to set up a comprehensive estate plan with a focus on philanthropy.',
    details: 'The estate plan for the Davis family should include the creation of philanthropic funds, trusts for heirs, and strategies for asset protection...',
    estimatedValue: 25000,
  },
  {
    id: 9,
    title: 'Rodriguez Estate Plan',
    summary: 'Estate planning expert needed for a multi-generational farm estate.',
    details: 'The Rodriguez family needs an estate plan that includes succession planning for the family farm, trusts, and estate administration guidance...',
    estimatedValue: 25000,
  },
  {
    id: 10,
    title: 'Martinez Estate Plan',
    summary: 'Custom estate planning services required for the Martinez family with a portfolio of tech investments.',
    details: 'The Martinez family is seeking an estate plan that covers their tech investment portfolio, educational trusts for children, and strategies for minimizing potential estate taxes...',
    estimatedValue: 25000,
  },
];

function Leads() {
  const [searchTerm, setSearchTerm] = useState('');
  const [lastEvaluatedKey, setLastEvaluatedKey] = useState(null);
  const [selectedRFP, setSelectedRFP] = useState(null);
  const [displayedRFPs, setDisplayedRFPs] = useState([]);
  const [page, setPage] = useState(1);
  const recordsPerPage = 4;
  const axiosWithCredentials = useAxiosWithCredentials();
  const handleCardClick = (rfp) => {
    setSelectedRFP(rfp);
  };

  const handleClose = () => {
    setSelectedRFP(null);
  };

  // Filter displayed RFPs based on search term
  const filteredRFPs = displayedRFPs.filter((rfp) =>
    rfp.title.toLowerCase().includes(searchTerm.toLowerCase())
  );
  // Mock fetchRFPs function
  const fetchRFPs = async (page, recordsPerPage) => {
    // // Calculate the slice of the rfpList to return
    // const start = (page - 1) * recordsPerPage;
    // const end = start + recordsPerPage;
    
    // // Return the slice of data as if it were fetched from an API
    // return Promise.resolve(rfpList.slice(start, end));
    
    try {
        // Replace this URL with your actual endpoint
        
        let url = '/crm/rfp/estate?recordsPerPage=10'
        if(lastEvaluatedKey)
          url = `${url}&lastEvaluatedKey=${lastEvaluatedKey}`
        
        const response = await axiosWithCredentials.get(url);
        setLastEvaluatedKey(response.lastEvaluatedKey)
        return response.data.Items
        
      } catch (error) {
        console.error("Error loading files:", error);
        // Handle errors appropriately
      }
  };
  
  useEffect(() => {
    fetchRFPs(page, recordsPerPage)
      .then(newRfps => {
        setDisplayedRFPs(prevRfps => [...prevRfps, ...newRfps]);
      });
    // We only want to trigger this effect when page changes, hence it's the only dependency
  }, [page]);
// Function to load RFPs
  // const loadRFPs = () => {
  //   // Assuming you have a function to fetch the RFPs
  //   // For example, fetchRFPs could be a function that retrieves RFPs from the backend
  //   // It would use the `page` and `recordsPerPage` to fetch the correct slice of data
  //   const newRFPs = fetchRFPs(page, recordsPerPage);
  //   setDisplayedRFPs(displayedRFPs.concat(newRFPs));
  //   setPage(page + 1);
  // };

  // Handle page change
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  // Function to load more RFPs
  const handleLoadMore = () => {
    setPage(prevPage => prevPage + 1);
  };


  return (
    <Box sx={{ padding: 2 }}>
      <TextField
        fullWidth
        placeholder="Search for RFPs..."
        InputProps={{
          startAdornment: <SearchIcon />,
        }}
        onChange={(e) => setSearchTerm(e.target.value)}
      />
      <Grid container spacing={2} sx={{ marginTop: 2 }}>
        {displayedRFPs.map((rfp) => (
          <Grid item xs={12} sm={6} md={4} key={rfp.id}>
            <Card>
              <CardActionArea onClick={() => handleCardClick(rfp)}>
                <CardContent>
  <Typography gutterBottom variant="h5" component="div">
    {rfp.title}
  </Typography>
  <Typography variant="subtitle1" color="text.secondary">
    {rfp.summary}
  </Typography>
  <Typography variant="body2" color="text.secondary">
    Posted On: {rfp.postedOn}
  </Typography>
  <Typography variant="body2" color="text.secondary">
    Responses: {rfp.numberOfResponses}
  </Typography>
  <Typography variant="body2" color="text.secondary">
    Estimated Value: ${rfp.estimatedValue.toLocaleString()}
  </Typography>
  <Typography variant="body2" color="text.secondary">
    Location: {rfp.location}
  </Typography>
</CardContent>
              </CardActionArea>
            </Card>
          </Grid>
        ))}
      </Grid>
      <Dialog open={Boolean(selectedRFP)} onClose={handleClose} maxWidth="sm" fullWidth>
        <DialogTitle>{selectedRFP?.title}</DialogTitle>
        <DialogContent>
          <Typography>{selectedRFP?.details}</Typography>
          {/* Include other actions like form inputs for submitting a proposal, etc. */}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
          <Button variant="contained" color="primary">
            Submit Proposal
          </Button>
          {/* Add more action buttons if needed */}
        </DialogActions>
      </Dialog>
      {displayedRFPs && displayedRFPs.length < rfpList.length && (
        <Box textAlign="center" sx={{ marginTop: 2 }}>
          <Button variant="contained" onClick={handleLoadMore}>
            Load More
          </Button>
        </Box>
      )}
    </Box>
    
  );
}


export default Leads;
