import React, { useState } from 'react';
import { Box, Tab, Tabs, TextField, Button, Typography } from '@mui/material';
import { TabContext, TabPanel } from '@mui/lab';
import useAxiosWithCredentials from '../hooks/useAxiosWithCredentials';

const ContactToolbox = ({ contact , onActivity}) => {
  const axiosWithCredentials = useAxiosWithCredentials();
  const [value, setValue] = useState('2');
  const [formData, setFormData] = useState({})
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleInputChange = (event) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };
  const createActivity = async (activity) => {
    try {
      // Replace this URL with your actual endpoint
      const response = await axiosWithCredentials.post(`/crm/activity/ua/${contact.contactId}`,
      activity
      );
      onActivity();
      
    } catch (error) {
      console.error("Error loading files:", error);
      // Handle errors appropriately
    }
  };

  const handleAction = (actionType) => {
    // Placeholder for action logic, e.g., API call to send email, save note, or schedule a call
    switch (actionType) {
    case 'note':
      createActivity({type: "note", details: formData.note});
      setFormData({})
      return;
    default:
      console.log(`${actionType} for`, contact);
      return;
  }
  };

  return (
    <Box sx={{ width: '100%', typography: 'body1' }}>
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={value} onChange={handleChange} aria-label="contact toolbox tabs">
            <Tab label="Note" value="2" />
            <Tab label="Schedule" value="1" />
            <Tab label="Email" value="3" />
            <Tab label="Voice Settings" value="3" />
          </Tabs>
        </Box>
        <TabPanel value="1">
          <Typography variant="h6">Schedule a Follow-up Call</Typography>
          <TextField
            label="Date and Time"
            type="datetime-local"
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
            margin="normal"
          />
          <Button variant="contained" color="primary" onClick={() => handleAction('Schedule Call')}>
            Schedule
          </Button>
        </TabPanel>
        <TabPanel value="2">
          <Typography variant="h6">Create a Note</Typography>
          <TextField
            label="Note"
            name="note"
            value={formData.note}
            multiline
            rows={4}
            fullWidth
            margin="normal"
            onChange={handleInputChange}
          />
          <Button variant="contained" color="primary" onClick={() => handleAction('note')}>
            Save Note
          </Button>
        </TabPanel>
        <TabPanel value="3">
          <Typography variant="h6">Send an Email</Typography>
          <TextField
            label="Subject"
            fullWidth
            margin="normal"
          />
          <TextField
            label="Message"
            multiline
            rows={4}
            fullWidth
            margin="normal"
          />
          <Button variant="contained" color="primary" onClick={() => handleAction('Send Email')}>
            Send Email
          </Button>
        </TabPanel>
      </TabContext>
    </Box>
  );
};

export default ContactToolbox;
