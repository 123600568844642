import React, { useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Grid } from '@mui/material';
import useAxiosWithCredentials from '../hooks/useAxiosWithCredentials';
function AddCustomerDialog({ open, onClose, onSuccess }) {
    const [customer, setCustomer] = useState({
        firstName: '',
        lastName: '',
        phone: '',
        email: '',
        address: '',
        company: '',
        notes: '',
    });
    const axiosWithCredentials = useAxiosWithCredentials();
  
    const [formData, setFormData] = useState({});
    const [editMode, setEditMode] = useState(false);


    const handleSave = async () => {

        try {
          const response = await axiosWithCredentials.post(`/crm/contact`, customer);
          if (response.status === 200 || response.status === 201) {
            console.log('Create successful');
            onSuccess();
          } else {
            console.error('Create failed');
            // Handle the upload failure (e.g., display error message)
          }
        } catch (error) {
          console.error('Error during Create', error);
          // Handle any errors that occurred during the upload process
        }
        onClose();
    };
  
    const handleChange = (e) => {
        setCustomer({ ...customer, [e.target.name]: e.target.value });
    };


    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>Add New Customer</DialogTitle>
            <DialogContent>
                <Grid container spacing={2}>
                    {Object.keys(customer).map((key) => (
                        <Grid item xs={12} sm={6} key={key}>
                            <TextField
                                autoFocus
                                margin="dense"
                                name={key}
                                label={key.charAt(0).toUpperCase() + key.slice(1)}
                                type="text"
                                fullWidth
                                variant="outlined"
                                value={customer[key]}
                                onChange={handleChange}
                            />
                        </Grid>
                    ))}
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Cancel</Button>
                <Button onClick={handleSave}>Add</Button>
            </DialogActions>
        </Dialog>
    );
}

export default AddCustomerDialog;
