// Layout.js
import React, {useState} from 'react';
import { Grid, GridItem } from '@chakra-ui/react'
import { Box, CssBaseline, AppBar, Toolbar, IconButton, Typography, Drawer, List, ListItem, ListItemIcon, Avatar } from '@mui/material';
import { Menu as MenuIcon, Home as HomeIcon, Settings as SettingsIcon, AccountCircle as AccountCircleIcon } from '@mui/icons-material';
import { Outlet } from 'react-router-dom';
import { Paper } from '@mui/material';
import ViewMenu from "./ViewMenu"

import ForumIcon from '@mui/icons-material/Forum';
import VoicemailIcon from '@mui/icons-material/Voicemail';
import VideocamIcon from '@mui/icons-material/Videocam';
import PhoneIcon from '@mui/icons-material/Phone'; // This is the telephone icon
import CustomerHeader from './CustomerHeader'
import ContactListControl from './ContactListControl'
import ActivityView from './ActivityView'
import ContactToolbox from './ContactToolbox'
import ActivityForm from './ActivityForm'
import ActivityLog from './ActivityLog'
import ContactDetails from './ContactDetails'
import Chat from './Chat'
import ZoomMeeting from './ZoomMeeting'
import ComPanel from './ComPanel'
export default function CallCenter() {
  const [selectedContact, setSelectedContact] = useState(null);
  
  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const [selectedActivity, setSelectedActivity] = useState(null);
  
  const [phoneNumber, setPhoneNumber] = useState('');
  const selectContact = (contact) => {
    // setContacts(contacts.filter(contact => contact.id !== contactId));
    setPhoneNumber(contact.phone)
    setSelectedContact(contact)
  };
  const [currentView, setCurrentView] = useState('phone');
  const switchViews = (view) =>{
      setCurrentView(view)
      // setDrawerOpen(false)
  }
  const handleDialButton = (key) => {
    setPhoneNumber(phoneNumber + key.value);
  };
  const iconMap = {
    phone: <PhoneIcon style={{ marginRight: '10px' }} />,
    message: <ForumIcon style={{ marginRight: '10px' }} />,
    video: <VideocamIcon style={{ marginRight: '10px' }} />,
    voicemail: <VoicemailIcon style={{ marginRight: '10px' }} />,
    // Add more mappings here if needed
  };
  
  
  const [editMode, setEditMode] = useState(false);
  const selectActivity = (activity) => {
    
    if(activity){
      setEditMode(true)
      setSelectedActivity(activity)
    }
    else{
      setEditMode(false)
      setSelectedActivity({
        date: "5/27/1977", 
        notes: '',
        type: 'Note'
      })
    }
    
    
  };
  
  
  
  
  return (
        <div style={{ display: 'flex' }}>
        <div style={{ flex: 1 }}>
        <ContactListControl onSelectContact={selectContact}/>
        </div>
        <div style={{ flex: 2 }}>
        
        {selectedContact && (
        <CustomerHeader contactId={selectedContact.contactId} />
        )}
        
        {currentView !=='video' && currentView !=='voicemail' &&
        <>
        <ContactToolbox contact={selectedContact} />
        {selectedContact && (
        <ActivityLog contactId={selectedContact.contactId}/>
        )}
        </>
        }
        {currentView ==='video' &&
        <ZoomMeeting selectedActivity={selectedActivity} setSelectedActivity={setSelectedActivity} editMode={editMode}/>
        }
        </div>
        <div style={{ flex: 1 }}>
        {currentView ==='phone' &&
            <ComPanel selectedContact={selectedContact} handleDialButton={handleDialButton} setPhoneNumber={setPhoneNumber} handleDialButton={handleDialButton} phoneNumber={phoneNumber} token={329085098} />
        }
        {currentView ==='message' &&
            <Chat selectedContact={selectedContact} handleDialButton={handleDialButton} setPhoneNumber={setPhoneNumber} handleDialButton={handleDialButton} phoneNumber={phoneNumber} token={329085098} />
        }
        {currentView ==='video' &&
            <Chat selectedContact={selectedContact} handleDialButton={handleDialButton} setPhoneNumber={setPhoneNumber} handleDialButton={handleDialButton} phoneNumber={phoneNumber} token={329085098} />
        }
        {currentView ==='voicemail' &&
            <ComPanel selectedContact={selectedContact} handleDialButton={handleDialButton} setPhoneNumber={setPhoneNumber} handleDialButton={handleDialButton} phoneNumber={phoneNumber} token={329085098} />
        }
        </div>
        </div>
  );
}
