import React, { useState } from 'react';
import { Box, Button, TextField, InputAdornment, FormControl, InputLabel, Select, MenuItem, IconButton, Typography } from '@mui/material';
import FilterListIcon from '@mui/icons-material/FilterList';
import SearchIcon from '@mui/icons-material/Search';
import AddIcon from '@mui/icons-material/Add';
import SettingsIcon from '@mui/icons-material/Settings';

const CustomerTableTopBar = ({ uniqueTags, totalCustomers, visibleCustomers, onAddNewCustomer }) => {
  const [selectedTag, setSelectedTag] = useState('all');
  const [searchTerm, setSearchTerm] = useState('');

  const handleTagChange = (event) => {
    setSelectedTag(event.target.value);
    // Implement the filtering logic based on the selected tag
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
    // Implement the search functionality
  };

  return (
    <Box display="flex" justifyContent="space-between" alignItems="center" p={2}>
      {/* Filter Dropdown */}
      <FormControl variant="outlined" size="small" sx={{ minWidth: 200 }}>
        <InputLabel id="tag-filter-label">Filter by Tag</InputLabel>
        <Select
          labelId="tag-filter-label"
          id="tag-filter"
          value={selectedTag}
          onChange={handleTagChange}
          label="Filter by Tag"
          startAdornment={<FilterListIcon />}
        >
          <MenuItem value="all">All Customers</MenuItem>
          {uniqueTags.map((tag, index) => (
            <MenuItem key={index} value={tag}>{tag}</MenuItem>
          ))}
        </Select>
      </FormControl>

      {/* Search Bar */}
      <TextField
        id="search-customers"
        size="small"
        placeholder="Search customers"
        value={searchTerm}
        onChange={handleSearchChange}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
      />
      <Typography flex/>
      {/* Add Customer Button */}
      <Button variant="contained" startIcon={<AddIcon />} onClick={onAddNewCustomer}>
        Add Customer
      </Button>

      {/* Settings Icon */}
      <IconButton onClick={() => {/* Open settings menu or dialog */}}>
        <SettingsIcon />
      </IconButton>

      {/* Caption */}
      <Typography variant="caption" display="block" gutterBottom>
        Viewing {visibleCustomers} of {totalCustomers} customers
      </Typography>
    </Box>
  );
};

export default CustomerTableTopBar;
