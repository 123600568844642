import React, { useState } from 'react';
import { List, ListItem, ListItemText, Collapse, Typography, IconButton } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

// Mockup data for activities
const activitiesData = [
  {
    id: 1,
    title: 'Send Intro to Don Dodge',
    timestamp: '2024-05-23T15:28:00.000Z',
    detail: 'Sent an introductory email to Don Dodge regarding potential partnership opportunities.',
  },
  // More activities...
];

const ActivityStream = () => {
  const [openActivities, setOpenActivities] = useState({}); // Tracks which activity details are open

  const handleClick = (id) => {
    setOpenActivities(prevOpenActivities => ({
      ...prevOpenActivities,
      [id]: !prevOpenActivities[id]
    }));
  };

  return (
    <List component="nav" aria-labelledby="nested-list-subheader">
      {activitiesData.map((activity) => (
        <React.Fragment key={activity.id}>
          <ListItem button onClick={() => handleClick(activity.id)}>
            <ListItemText primary={activity.title} secondary={new Date(activity.timestamp).toLocaleString()} />
            {openActivities[activity.id] ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </ListItem>
          <Collapse in={openActivities[activity.id]} timeout="auto" unmountOnExit>
            <Typography variant="body2" sx={{ pl: 4 }}>
              {activity.detail}
            </Typography>
          </Collapse>
        </React.Fragment>
      ))}
    </List>
  );
};

export default ActivityStream;
