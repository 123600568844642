import { Box, Button, Grid, Typography } from '@mui/material';

const Dialer = ({ handleDialButton}) => {

// Dial pad layout
  const dialPad = [
    {id:1, value: '1',},
    {id:2, value: '2', sub: "ABC"},
    {id:3, value: '3', sub: "DEF"},
    {id:4, value: '4', sub: "GHI"},
    {id:5, value: '5', sub: "JKL"},
    {id:6, value: '6', sub: "MNO"},
    {id:7, value: '7', sub: "PQRS"},
    {id:8, value: '8', sub: "TUV"},
    {id:9, value: '9', sub: "WXYZ"},
    {id:10, value: '*',},
    {id:0, value: '0',},
    {id:11, value: '#',},
  ];

  return (
        <Grid container spacing={1}>
          {dialPad.map((key) => (
            <Grid item xs={4} key={key.id}>
                <Button variant="outlined" fullWidth onClick={() => handleDialButton(key)}>
                  <Box display="flex" flexDirection="column" alignItems="center">
                    <Typography variant="h5" display="block" gutterBottom>
                      {key.value}
                    </Typography>
                    <Typography variant="caption" display="block" gutterBottom>
                      {key.sub || "\u00A0"}
                    </Typography>
                  </Box>
              </Button>
            </Grid>
          ))}
        </Grid>
  );
};

export default Dialer;
