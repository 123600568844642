import { useState, useEffect } from 'react';
import './App.css';

import CallCenter from './components/CallCenter'
import CRMLayout from './components/CRMLayout'
import Customers from './components/Customers'
import Customer from './components/Customer'
import LeadComponent from './components/LeadComponent'
import Leads from './components/Leads'
import Proposals from './components/Proposals'
import Proposal from './components/Proposal'
import Profile from './components/Profile'



import NotFound from './components/NotFound'
import {Route, Routes } from 'react-router-dom';

import { withAuthenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';


import Terms from './components/Terms'
import Privacy from './components/Privacy'
import OAuthCallback from './components/OAuthCallback';
import Logout from './components/Logout'; // Import your logout component
import SignIn from './components/SignIn'; // Import your logout component
import SignUp from './components/SignUp'; // Import your logout component

import RequireAuth from './components/RequireAuth';
import ForgotPassword from './components/ForgotPassword'

import ConfirmEmail from './components/ConfirmEmail'
import { MessageProvider } from './context/MessageContext'; 

function App() {

  return (

    <MessageProvider>
    <Routes>
      <Route path="/forgotpassword" element={<ForgotPassword />} />
      <Route path="/signin" element={<SignIn />} />
      <Route path="/signup" element={<SignUp />} />
      <Route path="/terms" element={<Terms />} />
      <Route path="/privacy" element={<Privacy />} />
      <Route path="/oauth2/idpresponse" element={<OAuthCallback />} />
      <Route path="/logout" element={<Logout />} />
      <Route path="/confirmemail" element={<ConfirmEmail />} />
      {/* authenticated routes */}
      <Route element={<RequireAuth />}>
        <Route element={<CRMLayout/>} >
          <Route path="/" element={<CallCenter />}/>
          <Route path="/connect" element={<CallCenter />}/>
          <Route path="/profile" element={<Profile />}/>
          <Route path="/customers" element={<Customers />}/>
          <Route path="/customers/:contactId" element={<Customer />}/>
          <Route path="/leads" element={<Leads />}/>
          <Route path="/proposals" element={<Proposals />}/>
          <Route path="/proposals/:proposalsId" element={<Proposal />}/>
        </Route>
      </Route>
        {/* catch all */} 
      <Route path="*" element={<NotFound/>}/>
      </Routes>
      </MessageProvider>
  );
}

export default App;