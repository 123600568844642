import React, { useState, useEffect } from 'react';
import { confirmSignUp } from '@aws-amplify/auth';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { Box, Typography, Button, Alert, CircularProgress } from '@mui/material';
import axios from "../api/axios";
const ConfirmEmail = () => {
  const [confirmationStatus, setConfirmationStatus] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const confirmUser = async () => {
      try {
        const token = searchParams.get('token');
        try {
          const response = await axios.get('/jwt/verify', {
            headers: {
              Authorization: `Bearer ${token}`  // Attach the JWT token in the header
            }
          });
          console.log(response)
          
          await confirmSignUp(response.data.email, token);
        setConfirmationStatus('Email confirmed successfully!');
        
        
          console.log('Verification result:', response.data);
        } catch (error) {
          console.error('Error verifying token:', error);
        }
          
        
        
        
        
      } catch (err) {
        setError(err.message || 'Failed to confirm your email. Please try again.');
      } finally {
        setLoading(false);
      }
    };

    confirmUser();
  }, [searchParams]);

  return (
    <Box sx={{ mt: 8, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      {loading ? (
        <CircularProgress />
      ) : error ? (
        <Alert severity="error">{error}</Alert>
      ) : (
        <>
          <Alert severity="success">{confirmationStatus}</Alert>
          <Button variant="contained" onClick={() => navigate('/signin')} sx={{ mt: 2 }}>
            Go to Sign In
          </Button>
        </>
      )}
    </Box>
  );
};

export default ConfirmEmail;
