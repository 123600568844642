// Layout.js
import React, {useState, useEffect} from 'react';
import { Flex } from '@chakra-ui/react'
import { Box, CssBaseline, AppBar, Toolbar, IconButton, Typography, Drawer, List, ListItem, ListItemIcon, Avatar } from '@mui/material';
import { Menu as MenuIcon, Home as HomeIcon, Settings as SettingsIcon, AccountCircle as AccountCircleIcon } from '@mui/icons-material';

import { FormControl, InputLabel, Select, MenuItem, Chip } from '@mui/material';
import FilterListIcon from '@mui/icons-material/FilterList'; // Import an icon for the filter


import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';

import { Outlet } from 'react-router-dom';
import { Grid } from '@mui/material';

import ForumIcon from '@mui/icons-material/Forum';

import { Link } from 'react-router-dom';
import VoicemailIcon from '@mui/icons-material/Voicemail';
import VideocamIcon from '@mui/icons-material/Videocam';
import PhoneIcon from '@mui/icons-material/Phone'; // This is the telephone icon

import ContactListControl from './ContactListControl'
import AddCustomerDialog from './AddCustomerDialog'
import CustomerTableTopBar from './CustomerTableTopBar'

import useAxiosWithCredentials from '../hooks/useAxiosWithCredentials';

export default function Customers() {
  const axiosWithCredentials = useAxiosWithCredentials();
  const [customers, setCustomers] = useState([]);  
  const [selectedContact, setSelectedContact] = useState(null);

  const [selectedActivity, setSelectedActivity] = useState(null);
  
  const [phoneNumber, setPhoneNumber] = useState('');
  const selectContact = (contact) => {
    // setContacts(contacts.filter(contact => contact.id !== contactId));
    setPhoneNumber(contact.phone)
    setSelectedContact(contact)
  };

  const handleDialButton = (key) => {
    setPhoneNumber(phoneNumber + key.value);
  };

  
  
  const [editMode, setEditMode] = useState(false);
  const selectActivity = (activity) => {
    
    if(activity){
      setEditMode(true)
      setSelectedActivity(activity)
    }
    else{
      setEditMode(false)
      setSelectedActivity({
        date: "5/27/1977", 
        notes: '',
        type: 'Note'
      })
    }
    
    
  };
  
  
  const [selectedTag, setSelectedTag] = useState('all');

  // An array of all unique tags from the customers data
  const uniqueTags = [...new Set(customers.flatMap(customer => customer.tags))];

  const handleTagChange = (event) => {
    setSelectedTag(event.target.value);
    // You would also implement filtering logic here
  };
  


// Function to extract initials from the company name
const getInitials = (name) => {
  return name.split(' ').map((word) => word[0]).join('');
};
const [filteredCustomers, setFilteredCustomers] = useState(customers); // State for filtered customers after search/filtering

 const totalCustomers = customers.length;
  const visibleCustomers = filteredCustomers.length;
  
  
  // Your state and functions to handle the logic

  // Example function for handling search changes
  const handleSearchChange = (searchTerm) => {
    const newFilteredCustomers = customers.filter(customer =>
      customer.summary.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredCustomers(newFilteredCustomers);
  };

  // Unique tags for dropdown, assuming each customer has a 'tags' array
    const [openDialog, setOpenDialog] = useState(false);

    const handleOpenDialog = () => {
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };
    
  const loadItems = async () => {
    try {
      // Replace this URL with your actual endpoint
      const response = await axiosWithCredentials.get(`/crm/contact`);
      console.log(JSON.stringify(response))
      setCustomers(response.data);
      
    } catch (error) {
      console.error("Error loading files:", error);
      // Handle errors appropriately
    }
  };
  
  
    useEffect(() => {
      loadItems();
    }, []); 
  
  return (
        <Flex>
        <Grid>
        <CustomerTableTopBar uniqueTags={uniqueTags}
        totalCustomers={totalCustomers}
        visibleCustomers={visibleCustomers}
        onTagChange={handleTagChange}
        onSearchChange={handleSearchChange}
        onAddNewCustomer={handleOpenDialog}
        />
        </Grid>
        <Grid xs={12}>
        <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Type</TableCell>
            <TableCell>Summary</TableCell>
            <TableCell>Email address</TableCell>
            <TableCell>Phone number</TableCell>
            <TableCell>Location</TableCell>
            <TableCell>Tags</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {customers.map((customer) => (
            <TableRow key={customer.contactId} component={Link} to={`/customers/${customer.contactId}`}>
              <TableCell>
                <Avatar src={customer.logo}>
                  {customer.logo ? '' : getInitials(customer.firstName + " " + customer.lastName)}
                </Avatar>
              </TableCell>
              <TableCell>{customer.firstName + " " + customer.lastName}</TableCell>
              <TableCell>{customer.email}</TableCell>
              <TableCell>{customer.phone}</TableCell>
              <TableCell>{customer.location}</TableCell>
              <TableCell>
                {customer.tags?.map((tag, index) => (
                  <Chip key={index} label={tag} />
                ))}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
        <AddCustomerDialog open={openDialog} onClose={handleCloseDialog} />
        </Grid>
        </Flex>
  );
}
