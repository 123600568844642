import React from 'react';
import { Box, Typography, Chip } from '@mui/material';

const LeadInfo = ({ leadDetails }) => {
  return (
    <Box sx={{ my: 2, p: 2, border: '1px solid #e0e0e0', borderRadius: '4px' }}>
      <Typography variant="h6" gutterBottom>Lead Fields</Typography>
      
      {/* Last Contacted */}
      <Typography variant="subtitle1" gutterBottom>Last Contacted</Typography>
      <Typography variant="body2" gutterBottom>{leadDetails.lastContacted}</Typography>
      
      {/* Lead Details */}
      {Object.entries(leadDetails.details).map(([key, value]) => (
        <Box key={key} mb={2}>
          <Typography variant="subtitle1">{key}</Typography>
          <Chip label={value} variant="outlined" />
        </Box>
      ))}

      {/* Active Deals */}
      {leadDetails.activeDeals && (
        <>
          <Typography variant="subtitle1" gutterBottom>Active Deals</Typography>
          {leadDetails.activeDeals.map((deal, index) => (
            <Box key={index} display="flex" justifyContent="space-between" alignItems="center" mb={1}>
              <Typography variant="body2">{deal.name}</Typography>
              <Typography variant="body2">{deal.value}</Typography>
              <Typography variant="body2">{`${deal.percentage}%`}</Typography>
            </Box>
          ))}
        </>
      )}
    </Box>
  );
};

export default LeadInfo;
