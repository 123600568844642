import { useState, useEffect, useRef } from 'react';
// import { Device } from '@twilio/voice-sdk';
import {IconButton, Tooltip, Switch, FormControlLabel, Box, Button, TextField, Grid, Paper, Typography, List, ListItem, ListItemText, Divider } from '@mui/material';
import useAxiosWithCredentials from '../hooks/useAxiosWithCredentials';
import { Tab, Tabs } from '@mui/material';
import { TabContext, TabPanel } from '@mui/lab';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline'; // Import the help outline icon
import TwilioPhone from './TwilioPhone'
import PhoneSettings from './PhoneSettings'
import VoicemailList from './VoicemailList'

let i=0
const ComPanel = ({ token, phoneNumber,handleDialButton,  setPhoneNumber, selectedContact, onActivity}) => {
  const [device, setDevice] = useState(null);
  const [callStatus, setCallStatus] = useState('Disconnected');
  
  const axiosWithCredentials = useAxiosWithCredentials()
  // Initialize Twilio Device
  // const setupDevice = (token) => {
  //   const options = { debug: true }
  //   const newDevice = new Device(token);
  //   setDevice(newDevice);
  // };
  
  // const fetchTokenAndSetupDevice = async () => {
  //   try {
  //     const response = await axiosWithCredentials.get(`/communication/twilio/token`);
  //     console.log(JSON.stringify(response))
  //     const data = await response.data;
  //     console.log(JSON.stringify(data))
  //     setupDevice(data.token);
  //   } catch (error) {
  //     console.error('Error fetching token: ', error);
  //   }
  // };
  // useEffect(() => {
  //   fetchTokenAndSetupDevice();
  // }, []);
  const logCall = async (call) => {
      try {
        // Replace this URL with your actual endpoint
        const response = await axiosWithCredentials.post(`/contacts/crm/activities/${selectedContact.contactId}`,
        call
        );
        onActivity();
        
      } catch (error) {
        console.error("Error loading files:", error);
        // Handle errors appropriately
      }
    };
  // Make a call
  const makeCall = async () => {
    if(voiceRecordingEnabled){
      try{
        const response = await axiosWithCredentials.post(`/communication/twilio/voice/initiate`, {
        toNumber: formatPhoneNumberForTwilio(phoneNumber,1)
        });
      }
      catch(err){
        console.log(err)
      }
    }
      else{
      try{
        const call = await device.connect(
          { params: 
            { To: formatPhoneNumberForTwilio(phoneNumber,1), record: true },
          });
        let callStartTimestamp;
        
      // newDevice.setup(token, options)
  
      call.on('accept', (call) => {
        console.log('Call accept:', call);
        callStartTimestamp = Date.now();
        setCallStatus('Call in progress');
      });
  
      call.on('disconnect', (call) => {
        if (callStartTimestamp) {
        // Calculate duration in seconds
        const callDurationSeconds = (Date.now() - callStartTimestamp) / 1000;
        console.log(`Call duration: ${callDurationSeconds} seconds`);
    
        logCall({
          type:"call",
          callDurationSeconds,
          phoneNumber
        })
    
    
        // Reset the start timestamp or handle it as needed
        callStartTimestamp = null;
        }
        console.log('Call disconnected:', call);
        setCallStatus('Call ended');
      });
        
      }
      catch(e){
        console.log(e)
      }
    }
  };
  function formatPhoneNumberForTwilio(inputNumber, defaultCountryCode) {
    // Remove non-numeric characters
    let cleanedNumber = inputNumber.replace(/\D/g, '');

    // Check if the number starts with a country code
    // Assuming country code does not start with '0'
    if (cleanedNumber.startsWith('0')) {
        cleanedNumber = defaultCountryCode + cleanedNumber.substring(1);
    } else if (!cleanedNumber.startsWith('1')) { // Assuming '1' is the default country code, like for the USA
        cleanedNumber = defaultCountryCode + cleanedNumber;
    }

    // Validate the length of the number here if needed

    return '+' + cleanedNumber;
}

  // Hang up the call
  const hangupCall = () => {
    device && device.disconnectAll();
  };
// Dial pad layout
  const dialPad = [
    {id:1, value: '1',},
    {id:2, value: '2', sub: "ABC"},
    {id:3, value: '3', sub: "DEF"},
    {id:4, value: '4', sub: "GHI"},
    {id:5, value: '5', sub: "JKL"},
    {id:6, value: '6', sub: "MNO"},
    {id:7, value: '7', sub: "PQRS"},
    {id:8, value: '8', sub: "TUV"},
    {id:9, value: '9', sub: "WXYZ"},
    {id:10, value: '*',},
    {id:0, value: '0',},
    {id:11, value: '#',},
  ];
  
    const [value, setValue] = useState('phone');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // const handleDialButton = (key) => {
  //   setPhoneNumber(phoneNumber + key.value);
  // };
  
  
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState('');
  const messageEndRef = useRef(null);

  const autoResponses = [
    "Sure, I can help with that.",
    "Could you provide more details?",
    "I'll need to check on that.",
    "Let's schedule a meeting to discuss.",
    // ... other responses
  ];
  const sendMessage = () => {
    // Add logic to send message to backend here
    setMessages([...messages, { sender: 'user', text: newMessage }]);
    setNewMessage('');
    
    // Simulate accountant's response
    setTimeout(() => {
      const accountantResponse = { sender: 'accountant', text: autoResponses[i++ %autoResponses.length] };
      setMessages(messages => [...messages, accountantResponse]);
    }, 1000); // Delay for realism
  };

  const scrollToBottom = () => {
    messageEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

const [voiceRecordingEnabled, setVoiceRecordingEnabled] = useState(false);
  
  const handleToggleRecording = (event) => {
    setVoiceRecordingEnabled(event.target.checked);
  };
useEffect(scrollToBottom, [messages]);
  return (
    <div style={{ height: '100%', display: 'flex', flexDirection: 'column', width: 350 }}>
      <Paper style={{margin: 'auto', flexGrow: 1, overflow: 'auto' }}>
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={value} onChange={handleChange} variant="fullWidth" indicatorColor="primary" textColor="primary">
             
              <Tab label="Voice" value="phone" />
              <Tab label="Messaging" value="message" />
            </Tabs>
          </Box>
          
          <TabPanel value="phone" style={{ padding: 0, width: 350, height: '100%'}}>
            <TwilioPhone selectedContact={selectedContact} handleDialButton={handleDialButton} setPhoneNumber={setPhoneNumber} handleDialButton={handleDialButton} phoneNumber={phoneNumber} token={329085098}selectedContact={selectedContact} handleDialButton={handleDialButton} setPhoneNumber={setPhoneNumber} handleDialButton={handleDialButton} phoneNumber={phoneNumber}/>
          </TabPanel>
          <TabPanel value="message">
            {/* Messaging UI goes here */}
            <Typography variant="h5" gutterBottom>
              Message
            </Typography>
            {!selectedContact &&
            <Typography variant="caption" color="secondary" gutterBottom>
              Select a contact or text someone new
            </Typography>
            }
            {selectedContact &&
            <Typography variant="h6" gutterBottom>
              {selectedContact.name}
            </Typography>
            }
            
            <Paper style={{ minHeight: 200, maxHeight: 500, overflow: 'auto', marginBottom: 8 }}>
            <Box sx={{ p: 2 }}>
              <Typography gutterBottom variant="h5" component="div">
                Chat
              </Typography>
            <Typography color="text.secondary" variant="body2">
              You can sync these messages to your phone SMS or other messageing service by updating Settings
            </Typography>
          </Box>
          <Divider light />
            <List>
              {messages.map((message, index) => (
                <ListItem key={index} alignItems="flex-start">
                  <ListItemText
                    primary={message.sender === 'user' ? 'You' : 'Accountant'}
                    secondary={<Typography variant="body2" color="textPrimary">{message.text}</Typography>}
                  />
                </ListItem>
              ))}
              <div ref={messageEndRef} />
            </List>
          </Paper>
          <TextField
            fullWidth
            label="Type a message"
            value={newMessage}
            onChange={(e) => setNewMessage(e.target.value)}
            onKeyDown={(e) => e.key === 'Enter' && sendMessage()}
            margin="normal"
            variant="outlined"
          />
          <Button variant="contained" color="primary" onClick={sendMessage}>
            Send
          </Button>
            {/* Implement messaging functionality */}
          </TabPanel>
           <TabPanel value="voicemail">
            <Typography variant="h5" gutterBottom>
              Voicemail
            </Typography>
            <VoicemailList />
          </TabPanel>
          <TabPanel value="video">
            {/* Video UI goes here */}
            <Typography variant="h5" gutterBottom>
              Start a Video Call
            </Typography>
            {/* Implement video call functionality */}
          </TabPanel>
         
        </TabContext>

        {/* The rest of your dialer code, adjust based on the selected tab */}
        {/* You may want to conditionally render parts of the UI based on the current mode */}
      </Paper>
    </div>
  );
};

export default ComPanel;
