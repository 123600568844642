import React, { useState } from 'react';
import { Button } from '@mui/material';

const PhoneControls = ({ incomingCall, makeCall, acceptCall, declineCall, hangupCall }) => {
  

  return (
    <div style={{ marginTop: '20px', textAlign: 'center' }}>
      {incomingCall ? (
        <>
          <Button color="success" variant="contained" onClick={acceptCall} style={{ marginRight: '10px' }}>
            Accept
          </Button>
          <Button color="error" variant="contained" onClick={declineCall}>
            Decline
          </Button>
        </>
      ) : (
        <>
          <Button color="primary" variant="contained" onClick={makeCall} style={{ marginRight: '10px' }}>
            Call
          </Button>
          <Button color="secondary" variant="contained" onClick={hangupCall}>
            Hang Up
          </Button>
        </>
      )}
    </div>
  );
};

export default PhoneControls;
