import React from 'react';
import { Box, Button, Tooltip } from '@mui/material';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import ScheduleIcon from '@mui/icons-material/Schedule';
import MessageIcon from '@mui/icons-material/Message';
import AddBusinessIcon from '@mui/icons-material/AddBusiness';
import LogIcon from '@mui/icons-material/PlaylistAddCheck'; // An icon to represent "Log Activity"

const InteractionButtons = () => {
  // Placeholder functions for demonstration. You'll need to replace them with real event handlers.
  const handleAddNote = () => {
    console.log('Add note');
    // Implement functionality
  };

  const handleLogActivity = () => {
    console.log('Log activity');
    // Implement functionality
  };

  const handleSchedule = () => {
    console.log('Schedule');
    // Implement functionality
  };

  const handleMessage = () => {
    console.log('Message');
    // Implement functionality
  };

  const handleAddDeal = () => {
    console.log('Add deal');
    // Implement functionality
  };

  return (
    <Box display="flex" justifyContent="space-between" flexWrap="wrap" gap={2}>
      <Tooltip title="Add Note">
        <Button startIcon={<NoteAddIcon />} onClick={handleAddNote}>
          Add Note
        </Button>
      </Tooltip>
      
      <Tooltip title="Log Activity">
        <Button startIcon={<LogIcon />} onClick={handleLogActivity}>
          Log Activity
        </Button>
      </Tooltip>

      <Tooltip title="Schedule">
        <Button startIcon={<ScheduleIcon />} onClick={handleSchedule}>
          Schedule
        </Button>
      </Tooltip>

      <Tooltip title="Message">
        <Button startIcon={<MessageIcon />} onClick={handleMessage}>
          Message
        </Button>
      </Tooltip>

      <Tooltip title="Add Deal">
        <Button startIcon={<AddBusinessIcon />} onClick={handleAddDeal}>
          Add Deal
        </Button>
      </Tooltip>
    </Box>
  );
};

export default InteractionButtons;
