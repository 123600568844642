import { useState, useEffect, useRef } from 'react';
import { Device } from '@twilio/voice-sdk';
import {Container, IconButton, Tooltip, Switch, FormControlLabel, Box, Button, TextField, Grid, Paper, Typography, List, ListItem, ListItemText, Divider, BottomNavigation, BottomNavigationAction  } from '@mui/material';
import useAxiosWithCredentials from '../hooks/useAxiosWithCredentials';
import { Tab, Tabs } from '@mui/material';
import { TabContext, TabPanel } from '@mui/lab';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline'; // Import the help outline icon
import Keypad from './Keypad'
import PhoneControls from './PhoneControls'
import VoicemailList from './VoicemailList'
import PhoneSettings from './PhoneSettings'
import VoicemailIcon from '@mui/icons-material/Voicemail';
import SettingsIcon from '@mui/icons-material/Settings';
import DialpadIcon from '@mui/icons-material/Dialpad';
import RecordVoiceOverIcon from '@mui/icons-material/RecordVoiceOver';
import TranscriptIcon from '@mui/icons-material/Description';

let i=0
const TwilioPhone = ({ phoneNumber,handleDialButton,  setPhoneNumber, selectedContact, onActivity}) => {
  const [device, setDevice] = useState(null);
  const [callStatus, setCallStatus] = useState('Disconnected');
  const [callStartTime, setCallStartTime] = useState(null);
  const [callDuration, setCallDuration] = useState(0);
  const axiosWithCredentials = useAxiosWithCredentials();
  const [isRecording, setIsRecording] = useState(false);
  
  const [value, setValue] = useState('keypad');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  
  // Start or stop the call timer based on call status
    useEffect(() => {
        let interval = null;

        if (callStatus === 'In Progress') {
            setCallStartTime(Date.now());
            interval = setInterval(() => {
                setCallDuration(Math.floor((Date.now() - callStartTime) / 1000));
            }, 1000); // Update duration every second
        } else if (callStatus !== 'In Progress' && callStartTime) {
            clearInterval(interval);
            setCallDuration(0); // Reset duration when call is not in progress
        }

        return () => clearInterval(interval); // Cleanup interval on component unmount or status change
    }, [callStatus]);
    
    // Convert seconds to a more readable format (HH:mm:ss)
    const formatDuration = (seconds) => {
        const hours = Math.floor(seconds / 3600);
        const minutes = Math.floor((seconds % 3600) / 60);
        const remainingSeconds = seconds % 60;
        return [hours, minutes, remainingSeconds]
            .map(val => val < 10 ? `0${val}` : val)
            .join(':');
    };
  // Initialize Twilio Device
  const setupDevice = (token) => {
    const newDevice = new Device(token);
    try{
    newDevice
        .on('registered', () => {
          console.log('Registered:');
              // Do something
          })  
        .on('incoming', (call) => {
          console.log('Incoming connection:', call);
          // Here you can show an alert or update component state to display the call UI
          setIncomingCall(true);
          setCurrentCall(call);
          
        })
        .on('error', (error) => {
          console.error('Twilio Device Error:', error);
        });
    newDevice.register()
    
    }
    catch(e){
      console.log(e)
    }
    setDevice(newDevice);
  };
  
  // Function to hang up the current call
  const hangupCall = () => {
    device.disconnectAll();
    if (currentCall) {
      currentCall.disconnect();
      setCurrentCall(null);
      setIncomingCall(false);
    }
  };

  // Function to accept the incoming call
  const acceptCall = () => {
    if (currentCall) {
      currentCall.accept();
      setIncomingCall(false); // After accepting, it's no longer an incoming call
    }
  };

  // Function to decline the incoming call
  const declineCall = () => {
    if (currentCall) {
      currentCall.reject();
      setCurrentCall(null);
      setIncomingCall(false);
    }
  };
  
  const [incomingCall, setIncomingCall] = useState(false);
  const [currentCall, setCurrentCall] = useState(null);

  

  const fetchTokenAndSetupDevice = async () => {
    try {
      const response = await axiosWithCredentials.get(`/communication/twilio/token`);
      console.log(JSON.stringify(response))
      const data = await response.data;
      console.log(JSON.stringify(data))
      setupDevice(data.token);
    } catch (error) {
      console.error('Error fetching token: ', error);
    }
  };
  useEffect(() => {
    fetchTokenAndSetupDevice();
  }, []);
  const logCall = async (call) => {
      try {
        // Replace this URL with your actual endpoint
        const response = await axiosWithCredentials.post(`/crm/activity/ua/${selectedContact.contactId}`,
        call
        );
        
      } catch (error) {
        console.error("Error loading files:", error);
        // Handle errors appropriately
      }
    };
    function displayCallDetails(callDurationSeconds, direction, phoneNumber) {
    // Convert call duration from seconds to a more readable format (HH:mm:ss)
    const durationHours = Math.floor(callDurationSeconds / 3600);
    const durationMinutes = Math.floor((callDurationSeconds % 3600) / 60);
    const durationSeconds = callDurationSeconds % 60;
    
    const paddedHours = String(durationHours).padStart(2, '0');
    const paddedMinutes = String(durationMinutes).padStart(2, '0');
    const paddedSeconds = String(durationSeconds).padStart(2, '0');
    const formattedDuration = `${paddedHours}:${paddedMinutes}:${paddedSeconds}`;
    
    // Determine the call direction for display
    const formattedDirection = direction === 'inbound' ? 'Incoming' : 'Outgoing';
    
    // Create and display the log message
    const logMessage = `${formattedDirection} call from/to number: ${phoneNumber}, Duration: ${formattedDuration}`;
    
    return logMessage
    // Alternatively, if this is for a web application, you might want to display this in the UI rather than logging to the console.
    // For example, you could append this message to a div element in your HTML.
}
  // Make a call
  const makeCall = async () => {
    // if(voiceRecordingEnabled){
    //   try{
    //     const response = await axiosWithCredentials.post(`/communication/twilio/voice/initiate`, {
    //     toNumber: formatPhoneNumberForTwilio(phoneNumber,1)
    //     });
    //   }
    //   catch(err){
    //     console.log(err)
    //   }
    // }
    //   else{
      try{
        const call = await device.connect(
          { params: 
            { To: formatPhoneNumberForTwilio(phoneNumber,1), record: true },
          });
          
        let callStartTimestamp;
        setCurrentCall(call);
      // newDevice.setup(token, options)
  
      call.on('accept', (call) => {
        console.log('Call accept:', call);
        callStartTimestamp = Date.now();
        setCallStatus('In Progress');
      });
  
      call.on('disconnect', (call) => {
        if (callStartTimestamp) {
        // Calculate duration in seconds
        const callDurationSeconds = (Date.now() - callStartTimestamp) / 1000;
        const direction ="outbound"
        
        console.log(`Call duration: ${callDurationSeconds} seconds`);
    
        logCall({
          type:"call",
          details: displayCallDetails(callDurationSeconds, direction, phoneNumber) ,
          callDurationSeconds,
          phoneNumber
        })
    
    
        // Reset the start timestamp or handle it as needed
        callStartTimestamp = null;
        }
        console.log('Call disconnected:', call);
        setCallStatus('Call ended');
      });
        
      }
      catch(e){
        console.log(e)
      }
    // }
  };
  function formatPhoneNumberForTwilio(inputNumber, defaultCountryCode) {
    // Remove non-numeric characters
    let cleanedNumber = inputNumber.replace(/\D/g, '');

    // Check if the number starts with a country code
    // Assuming country code does not start with '0'
    if (cleanedNumber.startsWith('0')) {
        cleanedNumber = defaultCountryCode + cleanedNumber.substring(1);
    } else if (!cleanedNumber.startsWith('1')) { // Assuming '1' is the default country code, like for the USA
        cleanedNumber = defaultCountryCode + cleanedNumber;
    }

    // Validate the length of the number here if needed

    return '+' + cleanedNumber;
}

  
const [voiceRecordingEnabled, setVoiceRecordingEnabled] = useState(false);
  
  const handleToggleRecording = (event) => {
    setVoiceRecordingEnabled(event.target.checked);
  };
  return (
      <>
      {value === 'keypad' &&
        <Container>
        {!selectedContact &&
        <Typography variant="caption" color="secondary" gutterBottom>
          Select a contact or dial someone new
        </Typography>
        }
        {selectedContact &&
        <Typography variant="h6" gutterBottom>
          Call {selectedContact.name}
        </Typography>
        }
        <TextField
          fullWidth
          margin="normal"
          value={phoneNumber}
          onChange={(e) => setPhoneNumber(e.target.value)}
          variant="outlined"
          placeholder="Enter Phone Number"
        />

        <Keypad handleDialButton={handleDialButton}/>


        <PhoneControls incomingCall={incomingCall} makeCall={makeCall} acceptCall={acceptCall} declineCall={declineCall} hangupCall={hangupCall} />
          

          {/*
          <Button color="secondary" variant="contained" onClick={fetchTokenAndSetupDevice}>
            accepting calls
          </Button>
          */}
        <Typography variant="subtitle1" style={{ marginTop: '20px' }}>
          <p>Call Status: {callStatus}</p>
            {callStatus === 'In Progress' && <p>Call Duration: {formatDuration(callDuration)}</p>}
        </Typography>
      
        </Container>
      }
      {value === 'voicemail' && <VoicemailList />}
      {value === 'settings' && <PhoneSettings />}
        
        <BottomNavigation value={value} onChange={handleChange} showLabels style={{ position: 'relative', bottom: 0, left: 0, right: 0 , top:5}}>
        <BottomNavigationAction label="Keypad" value="keypad" icon={<DialpadIcon />} />
        <BottomNavigationAction label="Voicemail" value="voicemail" icon={<VoicemailIcon />} />
        <BottomNavigationAction label="Settings" value="settings" icon={<SettingsIcon />} />
        
      </BottomNavigation>
      <Button 
          label="Recording" 
          value="recording" 
          icon={<RecordVoiceOverIcon sx={{ color: isRecording ? 'red' : 'inherit' }} />} 
          onClick={() => setIsRecording(!isRecording)} // Toggle recording state on click
        />
      </>
  );
};

export default TwilioPhone;
