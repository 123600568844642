import React, { useState } from 'react';
import { Paper, Table, TableHead, Container, TableBody, TableRow, TableCell, TableContainer, TextField, FormGroup, FormControlLabel, Switch, TextareaAutosize, Button, Typography } from '@mui/material';

const daysOfWeek = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

const defaultBusinessHours = {
  Monday: { open: '09:00', close: '17:00' },
  Tuesday: { open: '09:00', close: '17:00' },
  Wednesday: { open: '09:00', close: '17:00' },
  Thursday: { open: '09:00', close: '17:00' },
  Friday: { open: '09:00', close: '17:00' },
  Saturday: { open: null, close: null }, // Example for closed
  Sunday: { open: null, close: null }, // Example for closed
};
const PhoneSettings = () => {
  const [businessHours, setBusinessHours] = useState(defaultBusinessHours);
  const [isAvailable, setIsAvailable] = useState(true);
  const [useBusinessHours, setUseBusinessHours] = useState(true);
  const [voicemailMessages, setVoicemailMessages] = useState({
    unavailable: '',
    outsideBusinessHours: '',
    onAnotherCall: '',
  });

  // Handler examples
  const handleBusinessHoursChange = (day, openOrClose, value) => {
    setBusinessHours((prev) => ({
      ...prev,
      [day]: { ...prev[day], [openOrClose]: value },
    }));
  };

  const handleVoicemailMessageChange = (type, value) => {
    setVoicemailMessages((prev) => ({
      ...prev,
      [type]: value,
    }));
  };

  return (
    <Container style={{ maxWidth: '25%' }}>
      <Typography variant="h6" gutterBottom>Phone Settings</Typography>
      <TableContainer component={Paper} style={{ marginBottom: '20px' }}>
        <Table size="small" aria-label="business hours table">
          <TableHead>
            <TableRow>
              <TableCell>Day</TableCell>
              <TableCell align="right">Open</TableCell>
              <TableCell align="right">Close</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {daysOfWeek.map((day) => (
              <TableRow key={day}>
                <TableCell component="th" scope="row">
                  {day}
                </TableCell>
                <TableCell align="right">
                  <TextField
                    type="time"
                    size="small"
                    value={businessHours[day]?.open || ''}
                    onChange={(e) => handleBusinessHoursChange(day, 'open', e.target.value)}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputProps={{
                      step: 300, // 5 min
                    }}
                    variant="standard"
                  />
                </TableCell>
                <TableCell align="right">
                  <TextField
                    type="time"
                    size="small"
                    value={businessHours[day]?.close || ''}
                    onChange={(e) => handleBusinessHoursChange(day, 'close', e.target.value)}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputProps={{
                      step: 300, // 5 min
                    }}
                    variant="standard"
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Availability Toggle */}
      <FormControlLabel
        control={<Switch checked={isAvailable} onChange={(e) => setIsAvailable(e.target.checked)} />}
        label="Available to Take Calls"
      />

      {/* Business Hours Override Toggle */}
      <FormControlLabel
        control={<Switch checked={useBusinessHours} onChange={(e) => setUseBusinessHours(e.target.checked)} />}
        label="Use Business Hours"
      />

      {/* Voicemail Message Configuration */}
      <Typography variant="subtitle1">Voicemail Messages</Typography>
      <TextareaAutosize
        aria-label="Voicemail Message (Unavailable)"
        minRows={3}
        placeholder="Voicemail Message when Unavailable"
        value={voicemailMessages.unavailable}
        onChange={(e) => handleVoicemailMessageChange('unavailable', e.target.value)}
        style={{ width: '100%', marginTop: '8px' }}
      />
      {/* Add inputs for other voicemail scenarios */}
      
      {/* Submit Button - Implement submission logic as needed */}
      <Button variant="contained" color="primary" style={{ marginTop: '16px' }}>
        Save Settings
      </Button>
    </Container>
  );
};

export default PhoneSettings;
