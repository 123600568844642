import {useLocation, Navigate, Outlet} from "react-router-dom";
import {useAuthenticator} from "@aws-amplify/ui-react"
 
 
 export default function RequireAuth({children}){
     const { authStatus } = useAuthenticator(context => [context.authStatus]);
     const location = useLocation();
     
     return(<>
      {authStatus === 'configuring' && 'Loading...'}
      {authStatus !== 'authenticated' ? <Navigate to="/signIn" state={{from: location}} replace /> : <Outlet />}
    </>)
     
 }