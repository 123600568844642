import React, { useState, useEffect } from 'react';
import { getCurrentUser, signIn , signInWithRedirect, rememberDevice} from '@aws-amplify/auth';
import {  useAuthenticator } from '@aws-amplify/ui-react'
import { useLocation, useNavigate, Navigate } from 'react-router-dom';
import {
  Container,
  Box,
  TextField,
  Button,
  Typography,
  Checkbox,
  FormControlLabel,Alert,
  Link,
  Grid,
  Divider,
} from '@mui/material';
import GoogleIcon from '@mui/icons-material/Google';
import FacebookIcon from '@mui/icons-material/Facebook';

const SignIn = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const message = location.state?.message || '';
  const {route,authStatus} = useAuthenticator(context => [
    context.route,
    context.authStatus
    ])
  let from = location.state?.from?.pathname || "/";

  const [formState, setFormState] = useState({ username: '', password: '', rememberMe: false });
  const [user, setUser] = useState(null);

  useEffect(() => {
    if (authStatus === "authenticated" && user) {
      navigate('/', from, { replace: true });
       
    }
  }, [route, navigate, from, user, authStatus]);

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const authenticatedUser = await getCurrentUser();
        setUser(authenticatedUser);
      } catch (error) {
        setUser(null);
      }
    };
    fetchUser();
  }, []);

  const handleChange = (e) => {
    setFormState({ ...formState, [e.target.name]: e.target.value });
  };

  const handleCheckboxChange = (e) => {
    setFormState({ ...formState, [e.target.name]: e.target.checked });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      console.log("Logging in with ", formState.username, formState.password);
      const signInResponse = await signIn({ username: formState.username, password: formState.password,
      clientMetadata: {
            app: "lifeflow",
            appVersion: process.env.REACT_APP_VERSION,
            appSource: process.env.REACT_APP_SOURCE,
            appUrl: process.env.REACT_APP_URL
          } });
      const authenticatedUser = await getCurrentUser();
      setUser(authenticatedUser);
    } catch (error) {
      console.error('Error signing in', error);
      alert(error.message || 'Error signing in');
    }
  };

  const handleGoogleSignIn = () => {
    signInWithRedirect({
      provider: "Google"
    });
  };

  const handleFacebookSignIn = () => {
    // Logic for Facebook Sign-In
  };


  return (
    <Container maxWidth="xs" sx={{ mt: 8 }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          backgroundColor: 'white',
          p: 4,
          borderRadius: 2,
          boxShadow: 3,
        }}
      >
        <Typography variant="h4" component="h1" gutterBottom>
          ArcaultCRM
        </Typography>
        <Typography variant="subtitle1" gutterBottom>
          Stay connected with your clients
        </Typography>
        <form onSubmit={handleSubmit} style={{ width: '100%' }}>
          <TextField
            margin="normal"
            fullWidth
            label="Email"
            name="username"
            value={formState.username}
            onChange={handleChange}
            required
          />
          <TextField
            margin="normal"
            fullWidth
            label="Password"
            name="password"
            type="password"
            value={formState.password}
            onChange={handleChange}
            required
          />
          {/*
          <FormControlLabel
            control={
              <Checkbox
                name="rememberDevice"
                checked={formState.rememberDevice}
                onChange={handleCheckboxChange}
                color="primary"
              />
            }
            label="Remember Device"
          />
          */}
          
          <Button type="submit" fullWidth variant="contained" sx={{ mt: 2, mb: 1 }}>
            Sign In
          </Button>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item>
              <Link href="/forgotpassword" variant="body2">
                Forgot Password?
              </Link>
            </Grid>
            <Grid item>
              <Link onClick={() => navigate('/signup')} variant="body2" sx={{ cursor: 'pointer' }}>
                Create an Account Here
              </Link>
            </Grid>
          </Grid>
          {message && (
          <Alert severity="success" sx={{ mt: 2 }}>
            {message}
          </Alert>
        )}
        {/*
          <Divider sx={{ mt: 3, mb: 2 }}>or</Divider>
          <Button
            fullWidth
            variant="outlined"
            startIcon={<GoogleIcon />}
            sx={{ mb: 1 }}
            onClick={handleGoogleSignIn}
          >
            Sign in with Google
          </Button>
          
          <Button
            fullWidth
            variant="outlined"
            startIcon={<FacebookIcon />}
            sx={{ mb: 1 }}
            onClick={handleFacebookSignIn}
          >
            Sign in with Facebook
          </Button>
          */}
        </form>
      </Box>
      <Box mt={4} textAlign="center">
        <Typography variant="body2" color="textSecondary">
          &copy; 2024 Arcault
        </Typography>
        <Link href="/privacy" variant="body2">
          Privacy Policy
        </Link>{' '}
        |{' '}
        <Link href="/terms" variant="body2">
          Terms of Service
        </Link>
      </Box>
    </Container>
  );
};

export default SignIn;
