
import React from 'react';
import { Box, Flex, Text, VStack } from '@chakra-ui/react'; 
import { NavLink } from 'react-router-dom';
import Icon from './common/Icon.js'
import { Outlet, useLocation } from 'react-router-dom';

const menuItems = [
  { url: "/connect", icon: "phone", label: "Connect" },
  { url: "/customers", icon: "groups", label: "Customers" },
  { url: "/leads", icon: "assignment", label: "Leads" },
  { url: "/proposals", icon: "handshake", label: "Proposals" },
];

const LeftNavMenu = ({ className }) => {
  return (
    <Flex
      direction="column"
      className={`navigation ${className}`}
    >
      <VStack spacing="2" align="stretch">
        {menuItems.map(({ url, icon, label }) => (
          <NavItem icon={icon} label={label} href={url} />
        ))}
      </VStack>
    </Flex>
  );
};

const NavItem = ({ icon, label, href }) => (
  <NavLink
    to={href}
    className="nav-item"
  >
    <Flex
      className="nav-item-inner"
    >
      <Icon name={icon} />
      <Text>{label}</Text>
    </Flex>
  </NavLink>
);

export default LeftNavMenu;