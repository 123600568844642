import React, { useEffect, useState } from 'react';
import { FormControl, InputLabel,Select, MenuItem,TextField, Button, Container } from '@mui/material';
import { SurveyCreator, SurveyCreatorComponent } from 'survey-creator-react';
import "survey-core/defaultV2.min.css";
import "survey-creator-core/survey-creator-core.min.css";
import { editorLocalization } from "survey-creator-core";
import en_extensions from './proposal/en_extentions.json'
import { registerLetterheadWidget } from './proposal/LetterheadWidget';
import surveyTemplateJson from './proposal/surveyDefinition.json'
import useAxiosWithCredentials from '../hooks/useAxiosWithCredentials';

const Proposal = ({editMode}) => {
  const axiosWithCredentials = useAxiosWithCredentials();
  // State to hold form data and the selected template
  const [formData, setFormData] = useState({ name: '', description: '', rfpRef: 'c4b824b8-10f1-7097-788f-16810d2b7846', selectedTemplate: '' });
  const [templates, setTemplates] = useState([]); // For dropdown options
  const [creator, setCreator] = useState(null);

  useEffect(() => {
    editorLocalization.locales["en_extensions"] = en_extensions; // Assuming en_extensions is defined
    editorLocalization.currentLocale = "en_extensions";

    const newCreator = new SurveyCreator(creatorOptions); // Assuming creatorOptions is defined
    setCreator(newCreator);

    // Fetch templates (assuming this is asynchronous, e.g., fetching from a backend)
    fetchTemplates().then(fetchedTemplates => {
      setTemplates(fetchedTemplates);
      // Optionally, automatically select the first template
      if (fetchedTemplates.length > 0) {
        setFormData(prevFormData => ({
          ...prevFormData,
          selectedTemplate: fetchedTemplates[0].id, // Assuming each template has an ID
        }));
      }
    });
  }, []);

  // Function to handle form data changes (except for the read-only rfpRef)
  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData(prevFormData => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  // Function to load the selected template into the SurveyCreator
  const loadTemplate = () => {
    const selectedTemplate = templates.find(template => template.id === formData.selectedTemplate);
    if (selectedTemplate && creator) {
      creator.text = JSON.stringify(surveyTemplateJson); // Assuming the template includes the survey JSON
    }
  };

  // Function to handle submitting the proposal
  const handleSubmit = () => {
    console.log("Submitting proposal:", formData);
    formData.proposal = creator.text
    handleSave();
  };
  const handleSave = async () => {
    if (editMode) {
        try {
          const response = await axiosWithCredentials.put(`/contacts/rfp/response/${formData.rfpRef}`, formData);
          if (response.status === 200 || response.status === 201) {
            console.log('Update successful');
            // loadItems();
          } else {
            console.error('Update failed');
            // Handle the upload failure (e.g., display error message)
          }
        } catch (error) {
          console.error('Error during Update', error);
          // Handle any errors that occurred during the upload process
        }
    } else {
        try {
          const response = await axiosWithCredentials.post(`/contacts/rfp/rfpResponse/${formData.rfpRef}`, formData);
          if (response.status === 200 || response.status === 201) {
            console.log('Create successful: ', formData);
            // loadItems();
          } else {
            console.error('Create failed', response);
            // Handle the upload failure (e.g., display error message)
          }
        } catch (error) {
          console.error('Error during Create', error);
          // Handle any errors that occurred during the upload process
        }
    }
    };

  return (
    <Container maxWidth="md">
      <h1>New Proposal</h1>
      <TextField
        sx={{ mt: 2 }}
        label="RFP Reference"
        name="rfpRef"
        fullWidth
        variant="outlined"
        value={formData.rfpRef}
        InputProps={{
          readOnly: true,
        }}
      />
      <TextField
        sx={{ mt: 2 }}
        label="Proposal Name"
        name="name"
        fullWidth
        variant="outlined"
        value={formData.name}
        onChange={handleChange}
      />
      <TextField
        sx={{ mt: 2 }}
        label="Proposal Description"
        name="description"
        fullWidth
        variant="outlined"
        multiline
        rows={3}
        value={formData.description}
        onChange={handleChange}
      />
      <FormControl fullWidth sx={{ mt: 2 }}>
        <InputLabel>Template</InputLabel>
        <Select
          value={formData.selectedTemplate}
          label="Template"
          onChange={handleChange}
          name="selectedTemplate"
        >
          {templates.map((template) => (
            <MenuItem key={template.id} value={template.id}>{template.name}</MenuItem>
          ))}
        </Select>
      </FormControl>
      <Button sx={{ mt: 2 }} variant="outlined" onClick={loadTemplate}>Load Template</Button>
      <Button sx={{ mt: 2, ml: 2 }} variant="contained" onClick={handleSubmit}>Submit Proposal</Button>
      {creator && <SurveyCreatorComponent creator={creator} />}
    </Container>
  );
};

export default Proposal;

// Dummy function to represent asynchronous template fetching
async function fetchTemplates() {
  // Simulate fetching templates from a backend
  return [
    { id: 'template1', name: 'Template 1', json: {/* Your template JSON */} },
    // Add more templates as needed
  ];
}
const creatorOptions = {
  showLogicTab: true,
  isAutoSave: true
};
