import React, { useState, useEffect  } from 'react';
import {Switch, FormControlLabel, Box, List, ListItem, Table, TableBody, TableCell, TableHead, TableRow, IconButton, Chip, TextField, Button, Dialog } from '@mui/material';
import {Tooltip, Card, CardContent, Typography, Avatar } from '@mui/material';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline'; // Import the help outline icon

import ListItemText from '@mui/material/ListItemText';
import ContactForm from './ContactForm'
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import PhoneIcon from '@mui/icons-material/Phone';

import useAxiosWithCredentials from '../hooks/useAxiosWithCredentials';

const getName = (contact) =>{
  return contact.firstName + " " + contact.lastName
}
function ContactListControl({onSelectContact}) {
  const axiosWithCredentials = useAxiosWithCredentials();
  const [contacts, setContacts] = useState([]);
  const [selectedContact, setSelectedContact] = useState([]);
  const [filterTags, setFilterTags] = useState([]);
  // ... other states for form, tags, etc.
  const selectContact = (contact) => {
    setSelectedContact(contact)
    onSelectContact(contact)
  };
  const initialContacts = [
  {
    id: 1,
    name: 'Alice Johnson',
    email: 'alice@example.com',
    phone: '123-456-7890',
    tags: ['client', 'priority'],
    activities: [
      {id:1, date: "01/12/2024  01:23 PM EST", type: "Call", notes: "Confirmed with customer that they were moving forward"},
      {id:2, date: "01/11/2024  01:23 PM EST", type: "Message", notes: "Followup with customer on thier question"},
      {id:3, date: "01/11/2024  01:23 PM EST", type: "Voicemail", notes: "Transcripted"},
      {id:4, date: "01/10/2024  01:23 PM EST", type: "Message", notes: "something else"},
      ]
  },
  {
    id: 2,
    name: 'Bob Smith',
    email: 'bob@example.com',
    phone: '987-654-3210',
    tags: ['prospect']
  },
  {
    id: 3,
    name: 'Charlie Brown',
    email: 'charlie@example.com',
    phone: '978-317-1876',
    tags: ['client']
  },
  {
    id: 4,
    name: 'Mitesh Suchak',
    email: 'mitesh@relivity.com',
    phone: '289-242-1242',
    type: "recents",
    tags: ['client', 'priority'],
    activities: [
      {id:1, date: "01/12/2024  01:23 PM EST", type: "Call", notes: "Confirmed with customer that they were moving forward"},
      ]
  },
  // ... more contacts
];

  useEffect(()=>{
    loadItems();
  }, [])

  const handleFilterChange = (selectedTags) => {
    setFilterTags(selectedTags);
    // Logic to filter contacts based on selected tags
  };

  const [isEditMode, setIsEditMode] = useState(false); // State to toggle between add and edit mode


const [contactSyncEnabled, setContactSyncEnabled] = useState(true);
  const [listFilter, setListFilter] = useState('activity');
  const [searchTerm, setSearchTerm] = useState('');

  const handleToggleChange = (event) => {
    setContactSyncEnabled(event.target.checked);
  };

  const handleListFilterChange = (newFilter) => {
    setListFilter(newFilter);
    // Optionally reset search term or keep it depending on UX needs
    setSearchTerm('');
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredContacts = contacts.filter(contact => {
    const matchFilter = listFilter === 'all' || contact.type === listFilter;
    const matchSearch = getName(contact).toLowerCase().includes(searchTerm.toLowerCase());
    return matchFilter && matchSearch;
  });
  

  const loadItems = async () => {
    try {
      // Replace this URL with your actual endpoint
      const response = await axiosWithCredentials.get(`/crm/contact`);
      console.log(JSON.stringify(response))
      setContacts(response.data)
      
    } catch (error) {
      console.error("Error loading files:", error);
      // Handle errors appropriately
    }
  };

  return (
      <Box sx={{ p: 5 }}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
      <FormControlLabel
        control={<Switch checked={contactSyncEnabled} onChange={handleToggleChange} />}
        label="Contact Sync"
      />
      <Tooltip title="Enabling Contact Sync will automatically log all interactions with selected contacts in the CRM system. This includes calls, messages, and notes.">
        <IconButton>
          <HelpOutlineIcon />
        </IconButton>
      </Tooltip>
    </div>
      <Box sx={{ width: 300, display: 'flex', justifyContent: 'center', flexWrap: 'wrap', gap: 1, my: 2 }}>
        {['recent', 'favorites', 'all'].map((filter) => (
          <Chip
            key={filter}
            label={filter.charAt(0).toUpperCase() + filter.slice(1)}
            clickable
            color={listFilter === filter ? 'primary' : 'default'}
            onClick={() => handleListFilterChange(filter)}
          />
        ))}
      </Box>
      <TextField
      sx={{ width: 300}}
        margin="normal"
        variant="outlined"
        placeholder="Search Contacts"
        value={searchTerm}
        onChange={handleSearchChange}
      />
      <List>
        {false ? (
          <Card sx={{ width: 300 }}>
            <CardContent>
              <Avatar sx={{ width: 56, height: 56, marginBottom: 2 }}>
                {/* If you have a URL for the avatar image, you can pass it to the Avatar component */}
              </Avatar>
              <Typography variant="h5" component="div">
                {getName(selectedContact)}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Email: {selectedContact.email}
                <br />
                Phone: {selectedContact.phone}
              </Typography>
            </CardContent>
          </Card>
        ) : (
          <Typography variant="subtitle1" color="text.secondary">
            Select a contact to view details
          </Typography>
        )}
        {filteredContacts.map(contact => (
          <ListItem alignItems="flex-start" button key={contact.id} onClick={() => selectContact(contact)}>
            <ListItemAvatar>
              <Avatar>
                <PhoneIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={getName(contact)}
              secondary={
                <>
                  <Typography
                    sx={{ display: 'inline' }}
                    component="span"
                    variant="body2"
                    color="text.primary"
                  >
                    {contact.phoneNumber}
                  </Typography>
                  {` — Last called: ${contact.lastCalled}`}
                </>
              }
            />
          </ListItem>
        ))}
      </List>
    </Box>
    );
}
export default ContactListControl;
